import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { YMaps, Map, Placemark, Polygon } from 'react-yandex-maps';
import Title from '../../components/Title'
import BreadCumps from '../../components/BreadCumps'
import DeliveryZone from '../../components/DeliveryZone'
import { deliveryPageAction } from '../../store/actions'
import { imgImport } from '../../helpers/helper'
import Hamburger from '../../components/Hamburger';
import { ScrollWrapper } from '../../components/ScrollWrapper';
import '../../style/pages/delivery.css'

import mapOptions from './mapOptions'

const Delivery = () => {
	const [delivery, setDelivery] = useState({})
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(deliveryPageAction()).then(res => setDelivery(res))
	}, [])
	return (
		<div>
			{delivery.delieveryZones &&
				<div className="container">
					<Hamburger />
					<Title style={{ margin: '-70px 0 0 0' }} title="Информация о доставке"></Title>
					<BreadCumps
						items={
							[
								{
									link: "/",
									name: 'Главная'
								},
								{
									link: "/delivery",
									name: 'Информация о доставке'
								},
								// {
								//     link: "/",
								//     name: checkers['category']? checkers['category'].name : 'Catalog'
								// }
							]
						}
					/>
					<div className="payment-types">
						<Title title="Способы оплаты:" style={{ margin: '-50px 0 0 0' }}></Title>
						<div className="payment-types-inner">
							{delivery.delieveryZones.map(item => {
								return (
									<div key={item.id} className="payment-type">
										<div className="payment-title">
											<img style={{ width: '16px', height: '16px', marginRight: '16px' }} src={'https://api.pittools.kz/storage/' + item.image} alt="" />
											<p className="payment-title-inner">{item.title}</p>
										</div>
										<div className="payment-description" dangerouslySetInnerHTML={{ __html: item.description }}>
										</div>
									</div>
								)
							})}
						</div>
						<section className='delivery-map'>
							<div className="delivery-map-title">
								<Title title="Карта зоны доставки"></Title>
								<img src={imgImport('delivery', 'map.png')} alt="" />
							</div>
						</section>
						<section className='delivery-zones'>
							<div className="delivery-zones-title">
								<p className='delevery_first_title'>Сумма заказов:</p>
								<p className='delevery_first_title'>Более 40 000 тг.</p>
								<p className='delevery_first_title'>Менее 40 000 тг.</p>
							</div>
							<div className="delivery-zones">
								{
									delivery.paymentMethods.map(item => {
										return (

											<DeliveryZone
												key={item.id}
												title={item.title}
												under={item.under}
												over={item.upper}

											/>
										)
									})
								}
							</div>
                            <div className="map">
                                <YMaps>
                                    <Map
                                        { ...mapOptions.map }
                                        width="100%"
                                        height={400}
                                    >
                                        <Placemark
                                            { ...mapOptions.placemark }
                                        />
                                        <Polygon
                                            { ...mapOptions.polygon }
                                        />
                                    </Map>
                                </YMaps>
                            </div>
						</section>
					</div>
				</div>
			}
		</div>
	)
}

export default ScrollWrapper(Delivery)
