import React from 'react'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getUserAction, updateUserAction } from "../../../store/actions";
import { useNavigate } from "react-router-dom";
import { imgImport, _storage } from "../../../helpers/helper";
import '../../../style/components/profile.css';
import { isInAvatarSaferSelector, isInTypeImageSaferSelector } from '../../../store/selectors';
import { updateAvatarAction } from '../../../store/reducers';

export const Profile = () => {
	const [userData, setUserData] = useState();
	const [showSuccess, setShowsuccess] = useState(false)
	const [indexTimer, setIndexTimer] = useState(false)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const user = useSelector(state => state.isAuthed);
	const [errorName, setErrorName] = useState(false);
	const [passErr, setPassErr] = useState(false)
	const [sizeEmail, setSizeEmail] = useState(false);
	const [rideAdress, setRideAdress] = useState(false);
	const [corrPass, setCorrPass] = useState(false);
	const [confPass, setConfPass] = useState(false);
	const [disable, setDisable] = useState(false);
	const [phoneNomber, setPhoneNumber] = useState(false);
	const safedAvatar = useSelector(isInAvatarSaferSelector);
	const typeOfIamge = useSelector(isInTypeImageSaferSelector);
	const [changeState, setChangeState] = useState(false);

	useEffect(() => {
		if (!_storage.get('smartg-token')) navigate('/login')

		if (_storage.get('smartg-token')) dispatch(getUserAction()).then(res => {
			setUserData(res.user)
			setChangeState(false);
		});
	}, [changeState]);

	const updateUser = async () => {
		const userChanged = {
			name: userData.name,
			phone_number: userData.phone_number,
			address: userData.address,
			email: userData.email,
			avatar: {
				mime: typeOfIamge.imag,
				data: safedAvatar.ava
			}
		}
		if (userData.password && (userData.password.length != 0 || userData.password.length > 8 || userData.password == userData.password_confirmation)) {
			userChanged['password'] = userData.password_confirmation ? userData.password : undefined;
			userChanged['password_confirmation'] = userData.password_confirmation
		}
		dispatch(await updateUserAction(userChanged)).then(res => {
			setShowsuccess(true)
			setUserData(res);
			dispatch(updateAvatarAction(res.user.avatar))
			setTimeout(() => {
				setShowsuccess(false);
				setChangeState(true);
				setDisable(false);
			}, 1200);
		})
	}

	return (
		<div className="cabinet_profile">
			{
				showSuccess &&
				<div className="success-modal">
					Ваш профиль успешно обновлён!
				</div>
			}
			<div className="profile-info">
				{
					userData &&
					<div className="profile-info-inner">
						<div className='profile-info-inner-items'>
							<div className='profile-info-inner-items-flex'>
								<img src={imgImport('cabinet', 'user.png')} alt="" />
								<div className='profile-info-inner-items-name'>Ваше имя</div>
							</div>
							<input type="text" autoComplete="off" value={userData.name} onInput={(e) => /[0-9]/.test(e.target.value) ? e.target.value = '' : setUserData({ ...userData, name: e.target.value })} />
							{
								errorName && <span style={{ color: 'red' }}>Введите имя</span>
							}
						</div>
						<div className='profile-info-inner-items'>
							<div className='profile-info-inner-items-flex'>
								<img src={imgImport('cabinet', 'Phone.png')} alt="" />
								<div className='profile-info-inner-items-name'>Ваш номер телефона</div>
							</div>
							<input type="text" autoComplete="off" value={userData.phone_number} onChange={(e) => e.target.value.length >= 12 && setPhoneNumber(false)} onInput={
								(e) => /[A-Za-z]/.test(e.target.value) ||
									e.target.value.length > 12 ?
									e.target.value = ''
									:
									setUserData({ ...userData, phone_number: e.target.value.replace(e.target.value.substr(0, 2), '+7') })
							}
							/>
							{phoneNomber && <span style={{ color: 'red' }}>Введите номер телофона</span>}
						</div>
						<div className='profile-info-inner-items'>
							<div className='profile-info-inner-items-flex'>
								<img src={imgImport('cabinet', 'location.png')} alt="" />
								<div className='profile-info-inner-items-name'>Ваш адрес</div>
							</div>
							<input type="text" value={userData.address} autoComplete="off" onInput={(e) => setUserData({ ...userData, address: e.target.value })} />
							{
								rideAdress && <span style={{ color: 'red' }}>Введите адрес</span>
							}
						</div>
						<div className='profile-info-inner-items'>
							<div className='profile-info-inner-items-flex'>
								<img src={imgImport('cabinet', 'email.png')} alt="" />
								<div className='profile-info-inner-items-name'>Ваша почта</div>
							</div>
							<input type="text" value={userData.email} autoComplete="off" onInput={(e) => { setUserData({ ...userData, email: e.target.value }) }} />
							{
								sizeEmail && <span style={{ color: 'red' }}>Введите почту</span>
							}
						</div>
						<div className='profile-info-inner-items'>
							<div className='profile-info-inner-items-flex'>
								<img src={imgImport('cabinet', 'password.png')} alt="" />
								<div className='profile-info-inner-items-name'>Ваш пароль</div>
							</div>
							<input type="password" placeholder='*********' autoComplete='off' onInput={(e) => setUserData({ ...userData, password: e.target.value })} />
							{passErr && <span style={{ color: 'red' }}>Введён некорректный пароль!</span>}
							{corrPass && <span style={{ color: 'red' }}>Пороль должен содержат 5 симболов!</span>}
						</div>
						<div className='profile-info-inner-items'>
							<div className='profile-info-inner-items-flex'>
								<img src={imgImport('cabinet', 'password.png')} alt="" />
								<div className='profile-info-inner-items-name'>Подтверждение пароля</div>
							</div>
							<input type="password" placeholder='*********' autoComplete="off" onInput={(e) => setUserData({ ...userData, password_confirmation: e.target.value })} />
							{passErr && <span style={{ color: 'red' }}>Введён некорректный пароль!</span>}
							{confPass && <span style={{ color: 'red' }}> Пороль должен содержат 5 симболов!</span>}
						</div>
						<button disabled={disable} onClick={async () => {
							if (!!indexTimer) {
								clearTimeout(indexTimer);
							}
							setIndexTimer(
								setTimeout(async () => {

									setDisable(true)
									if (userData?.password !== '' && userData?.password?.length < 5) {
										setCorrPass(true)
									}
									if (userData.password_confirmation?.length < 5) {
										setConfPass(true)
									}
									if (userData.email?.includes('@') && userData.phone_number?.length === 12) {
										setRideAdress(false);
										setPhoneNumber(false);
										setSizeEmail(false)
										if (userData.password && userData.password_confirmation && userData.password !== userData.password_confirmation) {
											setPassErr(true);
										} else if (userData.password && !userData.password_confirmation) {
											setPassErr(true);
										} else {
											await updateUser()
											setPhoneNumber(false)
											setRideAdress(false);
											setSizeEmail(false);
											setPassErr(false);
											setCorrPass(false);
											setConfPass(false);
										}

									} else {
										if (!userData.email?.includes('@')) {
											setSizeEmail(true)
										}
										if (userData.phone_number?.length < 12) {
											setPhoneNumber(true)
										}
									}

									setDisable(false)

								}, 500)
							);

						}} style={{ margin: '0 auto' }} className='make-order'>Сохранить</button>
					</div>
				}
			</div>
		</div>
	)
}
