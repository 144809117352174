import React from 'react'
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { wholePageAction } from '../../store/actions'
import '../../style/pages/whosales.css'
import BreadCumps from '../../components/BreadCumps'
import Title from '../../components/Title'
import Hamburger from '../../components/Hamburger';
import { ScrollWrapper } from '../../components/ScrollWrapper';

const WholeSale = () => {
	const dispatch = useDispatch()
	const [whole, setWhole] = useState({})
	useEffect(() => {
		dispatch(wholePageAction()).then(res => setWhole(res.whosales))
	}, [])
	return (
		<div className='who-sales-cont'>
			<div className="container">
				<Hamburger />
				<Title title="Оптовые продажи" style={{ margin: '-80px 0 0 0' }} ></Title>
				<BreadCumps
					items={
						[
							{
								link: "/",
								name: 'Главная'
							},
							{
								link: "/wholesale",
								name: 'Оптовые продажи'
							},
							// {
							//     link: "/",
							//     name: checkers['category']? checkers['category'].name : 'Catalog'
							// }
						]
					}
				/>
				<div className='who-sales'>
					<div className="whole-sale-img">
						<img src={`https://api.pittools.kz/storage/${whole.image}`} alt="" />
					</div>
					<div className="whole-sale-text">
						<p className="whole-sale-title">{whole.title}</p>
						<div className="whole-sale-desc" dangerouslySetInnerHTML={{ __html: whole.description }}></div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ScrollWrapper(WholeSale)
