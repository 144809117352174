import React from 'react'
import _ from 'lodash'
import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import Card from '../../components/Card'
import BreadCumps from '../../components/BreadCumps'
import Hamburger from '../../components/Hamburger';
import Title from '../../components/Title'
import { fetchObjectProduct } from '../../store/actions'
import { ScrollWrapper } from '../../components/ScrollWrapper';
import '../../style/pages/comparison.css'
import { _storage, imgImport } from '../../helpers/helper';
import api from '../../helpers/api';
import ReactElasticCarousel from 'react-elastic-carousel';

const Comparison = () => {
	const dispatch = useDispatch()
	const [comparisonItems, setComparisonItems] = useState([]);
	const [inCompare, setInCompare] = useState(false);
	const [deleteCompare, setDeleteCompare] = useState(false);
	const [deleted, setDeleted] = useState(true);

	useEffect(() => {
		let storage = _storage.get('smartg-comp') ? JSON.parse(_storage.get('smartg-comp')) : [];
		setInCompare(true);
		setDeleted(true);
		if (typeof storage == 'object' && storage.length > 0) {
			const promises = storage.map((item) => {
				return api._fetchObjectProduct(item)
			})
			const products = [];
			Promise.all(promises).then((responses) => {
				responses.forEach((res) => {
					products.push(res.data.product)
				})
				setComparisonItems(products)
			})
		} else if (typeof storage == 'object' && !storage.length) {
			setComparisonItems([])
		} else {
			dispatch(fetchObjectProduct(storage)).then(res => {
				setComparisonItems(prevState => {
					return [...prevState, res.product]
				})
			})
		}
	}, [deleteCompare])

	let comparisonItemsMutated = [];

	if (comparisonItems.length) {
		comparisonItemsMutated = _.map(comparisonItems, (item) => {
			_.forEach(comparisonItems, (otherItem) => {
				if (item.id != otherItem.id) {
					_.forEach(otherItem.characteristic, (typical) => {
						if (!_.find(item.characteristic, { name: typical.name })) {
							item.characteristic.push({
								id: typical.id,
								name: typical.name,
								value: 'Данные отсутствуют'
							})
						}
					})
				}
			})

			return item
		})

		comparisonItemsMutated = _.map(comparisonItemsMutated, (item) => {
			item.characteristic = _.sortBy(item.characteristic, 'name')

			return item
		})
	}

	const renderItems = () => {
		if (comparisonItemsMutated && comparisonItemsMutated.length) {
			return comparisonItemsMutated.map(item => {
				return (
					<Card
						deleteIcon={imgImport('cardDetails', 'cross.png')}
						characs={item.characteristics}
						charcster={item.characteristic}
						id={item.id}
						key={item.id}
						title={item.title}
						image={item.image}
						price={item.price}
						salePrice={item.salePrice ? item.salePrice : ''}
						article={item.setNumber}
						created={item.created_at}
						inCompare={inCompare}
						setDeleteCompare={setDeleteCompare}
						deleteCompare={deleteCompare}
					/>
				)
			})
		}

		return (
			<p className='none_Product'>Продукты отсутствуют</p>
		)
	}

	return (
		<div className='comp'>
			<div className="container">
				<Hamburger />
				<BreadCumps
					items={
						[
							{
								link: "/",
								name: 'Главная'
							},
							{
								link: "/comparison",
								name: 'Сравнение'
							},
						]
					}
				/>
				<Title title="Сравнение" style={{ margin: '-60px 0 0 0' }} />
				<div className="search-comparison">
					<div className="comp-items">
						<ReactElasticCarousel itemsToShow={window.innerWidth < 821 ? 2 : 4}>
							{renderItems()}
						</ReactElasticCarousel>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ScrollWrapper(Comparison);