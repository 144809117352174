import React from 'react'
import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import BreadCumps from '../../components/BreadCumps'
import { imgImport } from '../../helpers/helper';
import Hamburger from '../../components/Hamburger';
import { Link, useNavigate, useLocation, Outlet, Routes, Route } from "react-router-dom";
import { _storage } from "../../helpers/helper";
import '../../style/pages/cabinet.css'
import { avatarSaferAction, isInCartAction, isInCompoAction, isInFavsAction, typeImageSaferAction } from '../../store/reducers';
import { getUserAction } from '../../store/actions';
import { isInUpdatedAvatarSelector } from '../../store/selectors';
// const { path} = useRouteMatch();
// (path)
// const [nav,setNav] = useState([
//     {
//         link: `${path}/profile`,
//         title: `Профиль`
//     },
// ]);
const Cabinet = (routes) => {
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const location = useLocation();
	const [name, setName] = useState();
	const [imag, setImag] = useState();
	const updatedAvat = useSelector(isInUpdatedAvatarSelector);
	const [nav, setNav] = useState([
		{
			link: `profile`,
			title: `Данные пользователя`
		},
		{
			link: `favorite`,
			title: `Избранное`
		},
		{
			title: `История заказов`,
			link: `history`
		},
		{
			title: `Выйти`,
			link: `logout`
		}
	]);
	useEffect(() => {
		if (!_storage.get('smartg-token')) navigate('/login')
		dispatch(getUserAction()).then(res => {
			setName(res?.user?.name)
			setImag(res?.user?.avatar)
		})
	}, [updatedAvat])
	const curLink = useMemo(() => {
		const filtered = nav.filter((obj, index) => obj.link === location.pathname);
		return filtered.length > 0 ? filtered[0] : {};
	}, [location]);

	function getBase64(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		});
	}

	return (
		<div>
			<div className="container">
				<Hamburger />
				<BreadCumps
					items={
						[
							{
								link: "/",
								name: 'Главная'
							},
							{
								link: "/cabinet/profile",
								name: 'Личный кабинет'
							},
							// {
							//     link: "/",
							//     name: checkers['category']? checkers['category'].name : 'Catalog'
							// }
						]
					}
				/>
				<div className="cabinet" style={{ marginBottom: '3rem' }}>
					<div className="cabinet_menu">
						<ul>
							<div className='cabinet_menu_profile_items'>
								<div className='cabinet_menu_profile_items_flex'>
									<img src={'https://api.pittools.kz/storage/' + imag} alt="" />
									<p>{name}</p>
								</div>
								<label style={{ position: 'absolute' }} className='file-upload'>
									<img className='cabinet_menu_profile_camera_imag' src={imgImport('cabinet', 'camera.png')} alt="" />
									<input type="file" onChange={(event) => {
										if (event.currentTarget.files.length) {
											dispatch(typeImageSaferAction(event.currentTarget.files[0].type))
											getBase64(event.currentTarget.files[0]).then((base) => {
												dispatch(avatarSaferAction(base))
											})
										}
									}} className="change-avatar" />
								</label>
							</div>
							{
								nav.map((obj, i) => (
									<li key={i} style={{ display: 'flex', alignItems: 'center' }} className={curLink.link == obj.link ? "activeLink" : ""}>
										{obj.title === "Данные пользователя" ?
											<img style={{ margin: '0 5px 0 0' }} src={imgImport('cabinet', 'user.png')} alt="" />
											: obj.title === "Избранное" ? <img style={{ margin: '0 5px 0 0' }} src={imgImport('cabinet', 'love.png')} alt="" />
												: obj.title === "История заказов" ? <img style={{ margin: '0 5px 0 0' }} src={imgImport('cabinet', 'cort.png')} alt="" />
													: obj.title === "Выйти" ? <img style={{ margin: '0 5px 0 5px' }} src={imgImport('cabinet', 'exit.png')} alt="" /> : ''
										}
										<Link to={obj.link == `favorite` ? `/favorite` : obj.link} onClick={() => {
											if (obj.link == 'logout') {
												localStorage.clear();
												dispatch(isInCartAction());
												dispatch(isInFavsAction());
												dispatch(isInCompoAction());
											}
										}}>
											{obj.title}
										</Link>
									</li>
								))
							}
						</ul>
						<Routes>
							{routes.routes.map((route, i) => (
								<Route key={i} {...route} />
							))}
						</Routes>
						<Outlet />
					</div>
				</div>
			</div>
		</div>
	)
}

export default Cabinet
