import React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import BreadCumps from "../../components/BreadCumps";
import Title from "../../components/Title";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../style/pages/order.css";
import Dropdown from "../../components/Dropdown";
import {
  getUserAction,
  fetchProduct,
  postOrderAction,
} from "../../store/actions";
import { _storage } from "../../helpers/helper";
import Hamburger from "../../components/Hamburger";
import { ScrollWrapper } from "../../components/ScrollWrapper";
import ru from "date-fns/locale/ru";
import { clearCartAction } from "../../store/reducers";
import InputMask from "react-input-mask";

const Order = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [curDate, setCurDate] = useState(new Date());
  const [indexInterval, setIndexInterval] = useState(false);
  const [isReceiver, setIsReceiver] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [card, setCard] = useState(1);
  const [overallPrice, setOverallPrice] = useState(0);
  const [exactAddress, setExactAddress] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [numberError, setNumberError] = useState(false);
  const [numberErrorLength, setNumberErrorLength] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [chosen, setChosen] = useState({
    mainAddress: "г.Алматы, проспект Суюнбая - 215",
    address: "",
    delivery_status: 1,
    delivery_date: curDate.toJSON().slice(0, 10),
    delivery_time: "В течении рабочего дня",
    payment_type: card,
    phone: "",
    email: "",
    name: "",
    comment: "",
  });
  const [basket, setBasket] = useState([]);
  const [err, setErr] = useState(false);

  registerLocale("ru", ru);

  useEffect(() => {
    dispatch(getUserAction()).then((res) =>
      setChosen({ ...chosen, address: res.user.address })
    );
    if (!_storage.get("smartg-token")) navigate("/login");
    if (_storage.get("cart")) {
      const basketParsed = JSON.parse(_storage.get("cart"));
      let fullBasket = [];
      basketParsed.forEach((item) => {
        dispatch(fetchProduct(item.id)).then((res) => {
          let newItem = { count: item.count, ...res.product };
          fullBasket.push(newItem);
          if (fullBasket.length == basketParsed.length) {
            let overall = 0;
            fullBasket.forEach((item) => {
              overall +=
                item.count *
                (item.salePrice != null ? item.salePrice : item.price);
            });
            setOverallPrice(overall);
            setBasket(fullBasket);
          }
        });
      });
    }
  }, []);
  useEffect(() => {
    if (basket.length != 0) {
      let overall = 0;
      basket.forEach((item) => {
        overall +=
          item.count * (item.salePrice != null ? item.salePrice : item.price);
      });
      setOverallPrice(overall);
    }
  }, []);
  const changeCard = (arg) => {
    setCard(arg);
  };
  const getUser = () => {
    if (isReceiver) {
      setIsReceiver(false);
      setChosen({ ...chosen, name: "", email: "", phone: "" });
      return;
    } else {
      dispatch(getUserAction()).then((res) => {
        setChosen({
          ...chosen,
          name: res.user.name,
          email: res.user.email,
          phone: res.user.phone_number,
        });
        setIsReceiver(true);
      });
    }
  };
  const onChangeChosen = (e, val, isReceiver = false) => {
    // (e,val)
    setChosen({
      ...chosen,
      [e]: val,
    });
  };
  // useEffect(() => {
  //     if(_storage.get('cart')){
  //         let storage = JSON.parse(_storage.get('cart'))
  //         (storage)
  //         storage.map(item =>{
  //             dispatch(fetchProduct(item.id)).then(res => {
  //                 let newItem =  {count:item.count, ...res.product}
  //                 basket.push(newItem)
  //                 if(basket.length == storage.length){
  //                     let overallPrice = 0
  //                     basket.forEach(item =>{
  //                         overallPrice += item.salePrice ? item.salePrice * item.count : item.price * item.count
  //                     })
  //                     setOverall(overallPrice)
  //                     setCart(basket)
  //                 }
  //             })
  //         })
  //     }
  // }, [])
  const sendOrder = () => {
    if (!chosen.name || !chosen.email || !chosen.phone) {
      setErr(true);
      setTimeout(() => {
        setErr(false);
      }, 1500);
    } else {
      const products = [];

      basket.forEach((item) => {
        products.push({ quantity: item.count, id: item.id });
      });

      setChosen({ ...chosen, phone: chosen.phone.replace(" ", "") });

      dispatch(
        postOrderAction({
          ...chosen,
          products: products,
          total_price: overallPrice,
        })
      ).then((res) => {
        setShowSuccess(true);
        setTimeout(() => {
          _storage.remove("cart");
          setShowSuccess(false);
          dispatch(clearCartAction(false));
          navigate("/");
          setDisabled(false);
        }, 1500);
      });
    }
  };

  return (
    <div>
      <div className="container">
        <Hamburger />
        {showSuccess && (
          <div className="success-modal">
            {/* Ваш заказ принят! */}
            Ваш заказ обрабатывается!
          </div>
        )}
        {err && <div className="err-modal">Введите ваши данные!</div>}
        <BreadCumps
          items={[
            {
              link: "/",
              name: "Главная",
            },
            {
              link: `order`,
              name: "Оформление заказа",
            },
          ]}
        />
        <Title
          title="Оформление заказа"
          style={{ textAlign: "center", margin: "-30px 0 0 0" }}
        ></Title>
        <div className="order-inner">
          <div className="order-info">
            <form>
              <div>
                {/* <p className='order-field-title'>Адрес и время</p> */}
                <div>
                  <div className="conditions">
                    <p className="order-field-title">Условия доставки</p>
                    <div className="select-container">
                      <p>
                        <input
                          checked={chosen.delivery_status == 1 ? 1 : 0}
                          className="radio"
                          type="radio"
                          onChange={(e) => {
                            setExactAddress(false);
                            onChangeChosen("delivery_status", 1);
                          }}
                        />
                        <span className="radio_control" />
                        <label htmlFor="deliver">Курьером</label>
                      </p>
                      <p>
                        <input
                          checked={chosen.delivery_status == 1 ? 0 : 1}
                          className="radio"
                          type="radio"
                          onChange={(e) => {
                            setExactAddress(true);
                            onChangeChosen("delivery_status", 0);
                          }}
                        />
                        <span className="radio_control" />
                        <label htmlFor="byOwn">Самовывоз</label>
                      </p>
                    </div>
                  </div>
                  {exactAddress ? (
                    <p className="adress_for_place_information">
                      <label style={{ marginBottom: "7px" }}>
                        Адрес самовывоза:
                      </label>
                      <input
                        onChange={(e) =>
                          onChangeChosen("address", e.target.value)
                        }
                        value={
                          exactAddress ? chosen.mainAddress : chosen.address
                        }
                        placeholder={chosen.address}
                        type="text"
                      />
                    </p>
                  ) : (
                    <p className="adress_for_place_information">
                      <label style={{ marginBottom: "7px" }}>
                        Адрес доставки:
                      </label>
                      <input
                        onChange={(e) =>
                          onChangeChosen("address", e.target.value)
                        }
                        value={
                          exactAddress ? chosen.mainAddress : chosen.address
                        }
                        placeholder={chosen.address}
                        type="text"
                      />
                    </p>
                  )}
                  <p className="adress_date">
                    <label style={{ marginBottom: "7px" }}>
                      Выберите дату:
                    </label>
                    <DatePicker
                      selected={curDate}
                      onChange={(myDate) => {
                        if (myDate.getTime() < curDate.getTime()) {
                          setCurDate(curDate);
                        } else {
                          setCurDate(myDate);
                          onChangeChosen(
                            "delivery_date",
                            myDate.toJSON().slice(0, 10)
                          );
                        }
                      }}
                      locale="ru"
                    />
                  </p>
                  <p className="adress_place">
                    <Dropdown
                      chosen={chosen}
                      keyIndex="time"
                      setChosen={setChosen}
                      defaultTitle="В течении рабочего дня"
                      list={[`В течении рабочего дня`, `После рабочего дня`]}
                    />
                  </p>
                  {/* <p> */}
                  {/* УСЛОВИЯ ВОЗВРАТА */}
                  {/* </p> */}
                  {isReceiver ? (
                    <div className="your-info">
                      <p className="order-field-title">Ваша информация</p>
                      <div onClick={(e) => getUser()} className="imreciever">
                        <span>Я получатель</span>
                        <input
                          checked={isReceiver}
                          className="radio-receive"
                          type="radio"
                          onClick={(e) => getUser()}
                        />
                      </div>
                      <p>
                        <input
                          onChange={(e) =>
                            onChangeChosen("name", e.target.value)
                          }
                          value={chosen.name}
                          type="text"
                          className="name"
                          placeholder="Ваше имя"
                        />
                      </p>
                      <p>
                        <InputMask
                          mask="+7 999 999 99 99"
                          onChange={(e) =>
                            onChangeChosen("phone", e.target.value)
                          }
                          value={chosen.phone}
                          type="text"
                          placeholder="Ваш номер"
                          className="phone"
                        />
                      </p>
                      <p>
                        <input
                          onChange={(e) =>
                            onChangeChosen("email", e.target.value)
                          }
                          value={chosen.email}
                          type="text"
                          className="email"
                          placeholder="Ваш email"
                        />
                      </p>
                      <textarea
                        onChange={(e) =>
                          onChangeChosen("comment", e.target.value)
                        }
                        placeholder="Введите ваш комментарий"
                        className="comment"
                        name=""
                        id=""
                        cols="30"
                        rows="10"
                      ></textarea>
                    </div>
                  ) : (
                    <div className="your-info">
                      <p className="order-field-title">
                        Введите вашу информацию
                      </p>
                      <div onClick={(e) => getUser()} className="imreciever">
                        <span checked={isReceiver}>Я получатель</span>
                        <input className="radio-receive" type="radio" />
                      </div>
                      <p>
                        <input
                          onChange={(e) =>
                            /[0-9]/.test(e.target.value)
                              ? (e.target.value = "")
                              : onChangeChosen("name", e.target.value)
                          }
                          value={chosen.name}
                          type="text"
                          className="name"
                          placeholder="Ваше имя"
                        />
                        {nameError && (
                          <span
                            style={{
                              color: "red",
                              fontSize: "20px",
                              marginTop: "-13px",
                            }}
                          >
                            Введите имя
                          </span>
                        )}
                      </p>
                      <p>
                        <InputMask
                          mask="+7 999 999 99 99"
                          onChange={(e) =>
                            onChangeChosen("phone", e.target.value)
                          }
                          value={chosen.phone}
                          type="text"
                          placeholder="Ваш номер"
                          className="phone"
                        />
                        {numberError && (
                          <span
                            style={{
                              color: "red",
                              fontSize: "20px",
                              marginTop: "-10px",
                            }}
                          >
                            Введите номер
                          </span>
                        )}
                        {numberErrorLength && (
                          <span
                            style={{
                              color: "red",
                              fontSize: "20px",
                              marginTop: "-10px",
                            }}
                          >
                            Номер должен быть из 11 чисел
                          </span>
                        )}
                      </p>
                      <p>
                        <input
                          onInput={(e) => {
                            if (!e.target.value.includes("@")) {
                              setEmailError(true);
                              onChangeChosen("email", e.target.value);
                            } else {
                              setEmailError(false);
                              onChangeChosen("email", e.target.value);
                            }
                          }}
                          value={chosen.email}
                          type="email"
                          className="email"
                          placeholder="Ваш email"
                        />
                        {emailError && (
                          <span
                            style={{
                              color: "red",
                              fontSize: "20px",
                              marginTop: "-13px",
                            }}
                          >
                            Введите E-mail
                          </span>
                        )}
                      </p>
                      <textarea
                        placeholder="Введите ваш комментарий"
                        className="commentary"
                        name=""
                        id=""
                        cols="30"
                        rows="10"
                      ></textarea>
                    </div>
                  )}
                  <div className="way_of_buy">
                    <p className="order-field-title">Способ Оплаты</p>
                    <div className="select-container">
                      {/* <p>
												<input checked={card} className='radio' type="radio" onChange={(e) => changeCard(1)} />
												<span className="radio_control" />
												<label htmlFor="deliver">Оплата картой</label>
											</p> */}
                      <p>
                        <input
                          checked={card == 0 ? 1 : 0}
                          className="radio"
                          type="radio"
                          onChange={(e) => changeCard(0)}
                        />
                        <span className="radio_control" />
                        <label htmlFor="byOwn">Наличными</label>
                      </p>
                    </div>
                  </div>
                  <button
                    type="button"
                    disabled={disabled}
                    margin="1.4rem 0"
                    onClick={async () => {
                      if (!!indexInterval) {
                        clearTimeout(indexInterval);
                      }

                      setIndexInterval(
                        setTimeout(async () => {
                          setDisabled(true);

                          let phoneUnformated = chosen.phone
                            ? chosen.phone.replace("_", "")
                            : "";
                          phoneUnformated = phoneUnformated.split(" ").join("");

                          if (!chosen.email.includes("@")) {
                            setEmailError(true);
                          }
                          if (chosen.name.length < 2) {
                            setNameError(true);
                          }
                          if (!chosen.phone) {
                            setNumberError(true);
                          }
                          if (phoneUnformated.length < 12) {
                            setNumberErrorLength(true);
                          }
                          if (
                            chosen.email.includes("@") &&
                            chosen.name.length > 1 &&
                            phoneUnformated.length === 12
                          ) {
                            await sendOrder();
                            setShowSuccess(true);
                          } else {
                            console.log(
                              emailError,
                              chosen.name.length,
                              phoneUnformated.length
                            );
                            window.scrollTo(0, 450);
                            setDisabled(false);
                          }
                        }, 500)
                      );
                    }}
                    className="make-order"
                  >
                    Подтвердить заказ
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="order-items">
            <h1
              style={{
                fontSize: "1.6rem",
                margin: "0",
                marginBottom: "0.5rem",
              }}
            >
              Ваш заказ
            </h1>
            <div className="order-items-inner">
              {basket &&
                basket.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className="basket-item-list"
                      style={{ height: "100px" }}
                    >
                      <img
                        style={{ width: "80px", objectFit: "cover" }}
                        src={`https://api.pittools.kz/storage/${item.image}`}
                        alt=""
                      />
                      <div className="basket-info">
                        <span className="title">{item.title}</span>
                        <div className="count-price">
                          <span className="count-basket">{item.count} шт.</span>
                          <span className="basket-price">
                            {item.salePrice ? (
                              <div>
                                {JSON.stringify(item.salePrice)
                                  ?.toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                                тг.
                              </div>
                            ) : (
                              <div>
                                {JSON.stringify(item.price)
                                  ?.toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                                тг.
                              </div>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              <div className="basket-sum">
                <p>Сумма</p>

                <p>
                  {" "}
                  {JSON.stringify(overallPrice)
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  тг.
                </p>
              </div>
              <div className="basket-delivery">
                <p>Доставка</p>
                <p>{chosen.delivery_status == 1 ? 1000 : "0"}</p>
              </div>
              <div className="basket-overall">
                <p>Общая сумма</p>
                <p>
                  {chosen.delivery_status == 1
                    ? JSON.stringify(overallPrice + 1000)
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : JSON.stringify(overallPrice)
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  тг.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScrollWrapper(Order);
