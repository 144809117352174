import React from 'react'
import { imgImport } from '../../helpers/helper';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getSocialFormAction, mainPageAction } from "../../store/actions";
import Card from '../../components/Card'
import Hamburger from '../../components/Hamburger';
import Title from '../../components/Title'
import { isInJustPageSelector, mainPageSelector } from "../../store/selectors";
import { Link, useNavigate } from "react-router-dom";
import { ScrollWrapper } from '../../components/ScrollWrapper';
import '../../style/pages/main-page.css'
const MainPage = () => {
	const dispatch = useDispatch();
	const contents = useSelector(mainPageSelector);
	const [index, setIndex] = React.useState(0);
	const [showSubCat, setShowSubCat] = useState(false)
	const [showCatsId, setShowCatsId] = useState(null)
	const justPage = useSelector(isInJustPageSelector);
	const delay = 5000;
	const timeoutRef = React.useRef(null);
	const navigate = useNavigate();

	function resetTimeout() {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
	}
	useEffect(() => {
		resetTimeout();
		timeoutRef.current = setTimeout(
			() =>
				setIndex((prevIndex) =>
					contents.contents && prevIndex >= contents.contents.banners.length - 1 ? 0 : prevIndex + 1

				),
			delay
		);
		return () => {
			resetTimeout();
		};
	}, [index]);
	useEffect(() => {
		justPage.justPage = 1;
		if (!contents.contents || !contents.contents.categories) {

			dispatch(mainPageAction())
		}
	}, []);

	return (
		<div>
			<div className="container">
				<Hamburger />
				<div className="hero">
					<div className="sidebar">
						<div className="catalog-title">
							<img src={imgImport('mainPage', 'three-lines.svg')} alt="" />
							<Link to="/catalog" style={{ color: 'white', textDecoration: 'none' }}>
								<p>Каталог товаров</p>
							</Link>
						</div>
						<div className={contents.contents?.categories.length > 12 ? 'scrollType' : 'scrollNone'} >
							{contents.contents && contents.contents.categories.map(item => {
								return (
									<div key={item.id} className="category">
										<div className="cat-title-cont">
											<img className="cat-icon" src={'https://api.pittools.kz/storage/' + item.image} alt="" />
											<p className="cat-title" onClick={() => {
												navigate(`catalog/${item.id}/1`)
											}}>{item.title}</p>
											{
												item.subcategories.length > 0 ?////////////////////
													<img
														onClick={() => {
															setShowSubCat(!showSubCat)
															setShowCatsId(item.id)
															if (window.innerWidth < 820 && showCatsId !== item.id) {
																setShowSubCat(true);
																window.scrollTo(0, 330)
															}
															if (window.innerWidth > 820 && showCatsId !== item.id) {
																setShowSubCat(true);
															}
														}}
														className="cat-arrow"
														src={imgImport('mainPage', 'arrow-right.png')} alt=""
													/> : ''
											}
										</div>
										{window.innerWidth < 821 && item.subcategories.length > 0 && (showCatsId === item.id && showSubCat) && item.subcategories.map(subitem => {
											return (

												<div key={subitem.id} className={`subcatalog-mob${subitem.id} subcatalog-mob`}>

													<div onClick={() => {
														navigate(`catalog/sub-cat=${subitem.id}/1`)
													}}
														className="subcat">
														{subitem.title}
													</div>

												</div>
											)
										})
										}

									</div>
								)

							})
							}
						</div>

					</div>
					<div className="slideshow">
						{contents.contents && showSubCat && window.innerWidth > 821 && contents.contents.categories.map(item => {
							if (item.subcategories.length > 0) {
								return (
									<div key={item.id} className={`subcatalog${item.id} subcatalog`} style={{ display: (showCatsId === item.id && showSubCat) ? 'block' : 'none', overflowY: "scroll" }}>
										{item.subcategories && (showCatsId === item.id && showSubCat) && item.subcategories.map(subitem => {
											return (
												<div onClick={() => {
													navigate(`catalog/sub-cat=${subitem.id}/1`)
												}} className="subcat">
													{subitem.title}
												</div>
											)
										})}
									</div>
								)
							}
						})}
						<div
							style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
							className="slideshowSlider">

							{contents.contents && contents.contents.banners.map(item => {
								return (
									<div className="slide" key={item.id} style={{ backgroundImage: `url(https://api.pittools.kz/storage/${item.image})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
										<div className="slide-title">
											<p>{item.title}</p>
											<button style={{ cursor: 'pointer' }} className="know-more" onClick={() => navigate(item.link && item.link)}>Узнать больше</button>
											<div className="slideshowDots">
												{contents.contents && contents.contents.banners.map(item => {
													return (
														<div
															key={item.id}
															onClick={() => { setIndex(item.id - 1); }}
															className={`slideshowDot ${index == (item.id - 1) ? "active" : ""}`}></div>
													)
												})}
											</div>
										</div>
									</div>
								)
							})}
						</div>
						{contents.contents &&
							<div className="banners">
								<div className="banner" onClick={() => navigate(`catalog/event=${contents.contents.events[0].id}`)} style={{ backgroundImage: contents.contents.events[0].background ? `url(https://api.pittools.kz/storage/${contents.contents.events[0].background})` : `url(${imgImport('mainPage', 'cat-bg.png')})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
									<div className="banner-wrap">

										<p style={{ fontStyle: 'normal', fontSize: '16px', fontWeight: '700' }}>{contents.contents.events[0].title}</p>
										<p className='discount'>{contents.contents.events[0].description}</p>
										<img className="toolPic" src={'https://api.pittools.kz/storage/' + contents.contents.events[0].image} alt="" />
									</div>
								</div>
								<div className="banner" onClick={() => navigate(`catalog/event=${contents.contents.events[1].id}`)} style={{ backgroundImage: contents.contents.events[1].background ? `url(https://api.pittools.kz/storage/${contents.contents.events[1].background})` : `url(${imgImport('mainPage', 'cat-bg.png')})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
									<div className="banner-wrap">
										<div className="toolpic-cat-cont">

											<img className="toolPic-cat" src={'https://api.pittools.kz/storage/' + contents.contents.events[1].image} alt="" />
										</div>
										<p style={{ color: 'white', fontStyle: 'normal', fontSize: '17px', fontWeight: '700' }}>{contents.contents.events[1].title}</p>
									</div>
								</div>
								<div className="banner" onClick={() => navigate(`catalog/event=${contents.contents.events[2].id}`)} style={{ backgroundImage: contents.contents.events[2].background ? `url(https://api.pittools.kz/storage/${contents.contents.events[2].background})` : `url(${imgImport('mainPage', 'bg-discount-2.png')})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
									<div className="banner-wrap">

										<p style={{ fontStyle: 'normal', fontSize: '16px', fontWeight: '700' }} >{contents.contents.events[2].title}</p>
										<p className='discount'>{contents.contents.events[2].description}</p>
										<img className="toolPic" src={'https://api.pittools.kz/storage/' + contents.contents.events[2].image} alt="" />
									</div>
								</div>
							</div>
						}
					</div>
				</div>
				<div className="advantages">
					{contents.contents && contents.contents.advantages.map(item => {
						return (
							<div key={item.id} className="advantage">
								<div className="advantage-wrap">
									<div className="advantage-content">
										<div className="adv-first-row">
											<div className="white-circle-cont">
												<img src={imgImport('mainPage', 'white-circle.png')} alt="" />
												<img src={'https://api.pittools.kz/storage/' + item.image} alt="" className="advantage-icon" />
											</div>
										</div>
										<p className="advantage-title">{item.title}</p>
										<p className="advantage-desc" style={{ color: '#414141' }}>{item.description}</p>
									</div>
								</div>
							</div>
						)
					})}
				</div>

				<div className="about-us">
					<div className="left-half">
						<div className="left-half-wrap">
							<p className="left-half-title" style={{ fontWeight: '700', fontStyle: 'normal', fontSize: '30px', color: '#414141' }}>
								О компании <span style={{ color: '#414141', fontWeight: '700', fontStyle: 'normal', fontSize: '30px' }} >/</span>
							</p>
							<div className="left-desc" dangerouslySetInnerHTML={{ __html: contents.contents && contents.contents.about.description }}>
							</div>
						</div>
					</div>
					<div className="about-us-mid">
						<div className="red-about">
							<p>{contents.contents && contents.contents.about.description2}</p>
						</div>
						<div className="red-about-description">
							<p>{contents.contents && contents.contents.about.description3}</p>
							<img src={imgImport('mainPage', 'pulemet2.png')} alt="" />
						</div>
					</div>
					<div className="about-us-pic" style={{ backgroundImage: `url(${contents.contents && 'https://api.pittools.kz/storage/' + contents.contents.about.image2})` }}>

					</div>
				</div>
				<div className="popular-items">
					<Title title='Популярные товары' />
					<div className="popular-wrapper">
						{contents?.contents && contents?.contents?.popularProducts?.map(item => {
							return (
								<Card
									id={item.id}
									key={item.id}
									title={item.title}
									rating={item.rating}
									image={item.image}
									price={item.price}
									salePrice={item.salePrice ? item.salePrice : ''}
									article={item.setNumber}
									created={item.created_at}
								/>
							)
						})}
					</div>
				</div>
				<div className="quality">
					<div className="left-quality" style={{ backgroundImage: `url(${imgImport('mainPage', 'second-banner.png')})` }}>
						<div className="left-q-text">
							<p className="left-q-title" style={{ fontWeight: '700', fontSize: '30px', fontStyle: 'normal' }}>{contents.contents && contents.contents.quality.title}</p>
							<p className="left-q-desc" style={{ fontSize: '16px', fontWeight: '400', fontStyle: 'normal' }} >{contents.contents && contents.contents.quality.description}</p>
						</div>
						<img className='quality-image' src={contents.contents && 'https://api.pittools.kz/storage/' + contents.contents.quality.image} alt="" />
					</div>
					<div className="right-quality">
						<div className="right-quality-wrap">
							{contents.contents && contents.contents.miniAdvantages.map((item, index) => {
								return (
									<div key={index} style={{ marginRight: '2rem' }} className={'mini-adv' + ' ' + `mini-adv${index}`}>
										<div className="white-circle">
											<img style={{ width: '20px', height: '20px' }} className="mini-adv-pic" src={'https://api.pittools.kz/storage/' + item.image} alt="" />
										</div>
										<div className="mini-adv-text">
											<p className="min-adv-title" style={{ color: '#414141', fontStyle: 'normal', fontWeight: 'bold', fontSize: '18px' }}>
												{item.title}
											</p>
											<p className="min-adv-desc" style={{ color: '#414141' }}>
												{item.description}
											</p>
										</div>
									</div>
								)
							})}
						</div>
					</div>
				</div>
				<div className="new-items">
					<Title title='Новые Товары'></Title>
					<div className="new-items-wrapper">
						{contents.contents && contents.contents.newProducts.map(item => {
							return (
								<Card
									id={item.id}
									key={item.id}
									title={item.title}
									rating={item.rating}
									image={item.image}
									price={item.price}
									salePrice={item.salePrice ? item.salePrice : ''}
									article={item.setNumber}
									created={item.created_at}
								/>
							)
						})}
					</div>
				</div>
			</div>
		</div>
	)
}

export default ScrollWrapper(MainPage);
