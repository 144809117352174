import React from 'react'
import { imgImport, _storage } from '../../helpers/helper';
import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { fetchProduct, latestSeenAction, postNotificationAction } from "../../store/actions";
import BreadCumps from '../../components/BreadCumps'
import { ScrollWrapper } from '../../components/ScrollWrapper';
import { useParams } from 'react-router-dom'
import Title from '../../components/Title'
import Card from '../../components/Card'
import '../../style/pages/card-details.css'
import { useLocation, useNavigate } from 'react-router';
import { postCommentAction } from '../../store/actions';
import Hamburger from '../../components/Hamburger';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import AwesomeSliderStyles from '../../style/pages/awesomeSliderCustomStyles.css';
import { isInCartAction } from '../../store/reducers';

const CardDetail = () => {
	const {
		id
	} = useParams()

	const location = useLocation();
	const navigate = useNavigate()
	const dispatch = useDispatch()
	// const [rating, setRating] = useState(0)
	const [count, setCount] = useState(1)
	const [rating, setRating] = useState([])
	const [notRated, setNotRated] = useState([])
	const [recommendation, setRecommendation] = useState(0)
	const [product, setProduct] = useState({})
	const [similarProducts, setSimilarProducts] = useState([])
	const [isInCart, setIsInCart] = useState(false)
	const [comment, setComment] = useState({})
	const [showComment, setShowComment] = useState(false)
	const [err, setErr] = useState(false)
	const [sameProduct, setSameProduct] = useState(false);
	const [userData, setUserData] = useState({
		name: '',
		number: '+7',
		comment: '',
		product_id: null,
	});
	const [fakeName, setFakeName] = useState(false);
	const [fakeNumber, setFakeNumber] = useState(false);
	const [fakeComment, setFakeComment] = useState(false);
	const [fakeLengthNumber, setFakeLengthNumber] = useState(false);
	const [darkImagSide, setDarkImagSide] = useState(false);
	const [safeDarkImag, setSafeDarkImag] = useState(null);
	const [productsMoreFour, setProductsMoreFour] = useState(false);
	const [newComment, setNewComment] = useState({
		text: '',
		rating: '',
		title: ''
	})
	//New
	const [showModal, setShowModal] = useState(false);
	const [showModalSlider, setShowModalSlider] = useState(false);
	const [showSomeText, setShowSomeText] = useState(false);
	const callModal = () => setShowModal(true);
	const [callback, setCallBack] = useState({
		name: '',
		phone: '+7',
		comment: ''
	})
	let [currentImage, setCurrentImage] = useState(null);

	useEffect(() => {

		dispatch(fetchProduct(id)).then(res => {
			setSimilarProducts(res.random)
			let rate = 0
			let rated = []
			let notrated = []
			if (res.comments.length > 0) {
				res.comments.forEach(item => {
					item.rating > 3 && setRecommendation(recommendation + 1)
					rate = rate + item.rating
				})
				for (let i = 0; i < Math.round(rate / res.comments.length); i++) {
					rated.push(i)
				}
				for (let i = rated.length; i < 5; i++) {
					notrated.push(i)
				}
				setComment(res.comments)
				setRating(rated)
				setNotRated(notrated)
				setSameProduct(false)
			}
		})

		window.scrollTo(0, 0)

	}, [sameProduct, currentImage])

	useEffect(() => {
		dispatch(fetchProduct(id)).then(res => {
			// (JSON.parse(_storage.get('cart')))
			if (_storage.get('cart')) {
				JSON.parse(_storage.get('cart')).forEach(item => {
					(item.id == res.product.id) && setIsInCart(true)
				})
			}
			dispatch(latestSeenAction(res.product))
			setProduct(res.product)
			setSameProduct(false)
		})
	}, [sameProduct, currentImage])

	const setStorage = () => {
		let item = {
			id: product.id,
			count
		}
		if (!_storage.get('cart')) {
			_storage.set('cart', JSON.stringify([item]))
		} else {
			let cart = []
			let jsonCart = JSON.parse(_storage.get('cart'))
			cart = jsonCart
			cart.push(item)
			_storage.set('cart', JSON.stringify(cart))
		}
		dispatch(isInCartAction(item))
		setIsInCart(true)
	}

	const sendNotyfyArrival = () => {
		dispatch(postNotificationAction(userData));
		console.log('SENDED , THE DATA', userData);
		setFakeName(false);
		setFakeNumber(false);
		setFakeLengthNumber(false);
		setFakeComment(false);
	}

	const sendComment = () => {
		if (!_storage.get('smartg-token')) {
			navigate('/login')
		}
		if (!newComment.text || !newComment.title && !newComment.rating) {
			setErr(true)
		} else {
			dispatch(postCommentAction(newComment, product.id)).then(res => {
				setShowComment(false)
			})
		}
	}

	return (
		<div>
			<div className="container">
				<Hamburger />
				<BreadCumps
					items={
						[
							{
								link: "/",
								name: 'Главная'
							},
							{
								link: ``,
								name: product.title
							},
						]
					}
				/>
				<div className="product-info">
					<div className={showModal || showModalSlider ? "died_block" : "product-photos"}>
						<AwesomeSlider cssModule={AwesomeSliderStyles} bullets={false} buttons={true}
							onTransitionRequest={() => setCurrentImage(null)} >
							{
								product?.image?.map(item => {
									return (
										<img key={item} onClick={() => {
											setSafeDarkImag(item);
											setDarkImagSide(true);
											setShowModalSlider(true);
										}} className='main-image' style={{ cursor: 'pointer' }} data-src={currentImage ? currentImage : 'https://api.pittools.kz/storage/' + item} />
									)
								})
							}
						</AwesomeSlider>

						<div className='_mini_sliders'>
							<img onClick={(item) => {
								setCurrentImage(item.target.src)
							}} style={{ objectFit: "contain" }} className={product?.image?.length > 0 ? '_mini_sliders_imag' : 'extra'} src={product.image && 'https://api.pittools.kz/storage/' + product.image[0]} alt="" />
							<img onClick={(item) => {
								setCurrentImage(item.target.src)
							}} style={{ objectFit: "contain" }} className={product?.image?.length > 1 ? '_mini_sliders_imag' : 'extra'} src={product.image && 'https://api.pittools.kz/storage/' + product.image[1]} alt="" />
							<img onClick={(item) => {
								setCurrentImage(item.target.src)
							}} style={{ objectFit: "contain" }} className={product?.image?.length > 2 ? '_mini_sliders_imag' : 'extra'} src={product.image && 'https://api.pittools.kz/storage/' + product.image[2]} alt="" />
						</div>
					</div>
					{
						darkImagSide && <div className='__main_image_modal'>
							<div className='__main_image_modal_siba'>
								<AwesomeSlider cssModule={AwesomeSliderStyles} bullets={false} buttons={true}
									onTransitionRequest={() => setCurrentImage(null)} >
									{
										product?.image?.map(item => {
											return (
												<img key={item} className='main-image' style={{ cursor: 'pointer' }} data-src={currentImage ? currentImage : 'https://api.pittools.kz/storage/' + item} />
											)
										})
									}
								</AwesomeSlider>
								<img onClick={() => {
									setShowModalSlider(false);
									setDarkImagSide(false);
								}} className="__main_dark_imag_close" style={{ display: 'block !important' }} src={imgImport('cardDetails', 'cross.png')} alt="" />
							</div>
						</div>
					}
					<div className="product-info-inner">
						<p className="product-title">{product.title}</p>
						{
							product.available === 1 ?
								<p> Есть в наличии </p>
								:
								<span>Нет в наличии</span>
						}
						<div className="product-row">
							<div className="rating">
								<div className="rating-container-flex">
									<p className="rating-title">Рейтинг: </p>

									{
										product?.grade == 5 || product?.grade == 4.5 || product?.grade == 4.6 || product?.grade == 4.7 || product?.grade == 4.8 || product?.grade == 4.9 ?
											<div className="rating-inner">
												<img src={imgImport('cardDetails', 'gold-star.png')} alt="" />
												<img src={imgImport('cardDetails', 'gold-star.png')} alt="" />
												<img src={imgImport('cardDetails', 'gold-star.png')} alt="" />
												<img src={imgImport('cardDetails', 'gold-star.png')} alt="" />
												<img src={imgImport('cardDetails', 'gold-star.png')} alt="" />
												<img className='information__png' onMouseOver={() => setShowSomeText(true)} onMouseLeave={() => setShowSomeText(false)} style={{ width: '20px', heigth: '20px', margin: '0 0 -3.8px 6px', cursor: 'pointer', borderRadius: '50%' }} src={imgImport('cardDetails', 'information.png')} alt="" />
												{showSomeText &&
													<span className='auto__rating'>
														Рейтинг формируется автоматически на основе продаж
													</span>
												}
											</div>
											: ''
									}

									{
										product?.grade == 4 || product?.grade == 3.5 || product?.grade == 3.6 || product?.grade == 3.7 || product?.grade == 3.8 || product?.grade == 3.9 ?
											<div className="rating-inner">
												<img src={imgImport('cardDetails', 'gold-star.png')} alt="" />
												<img src={imgImport('cardDetails', 'gold-star.png')} alt="" />
												<img src={imgImport('cardDetails', 'gold-star.png')} alt="" />
												<img src={imgImport('cardDetails', 'gold-star.png')} alt="" />
												<img className='information__png' onMouseOver={() => setShowSomeText(true)} onMouseLeave={() => setShowSomeText(false)} style={{ width: '20px', heigth: '20px', margin: '0 0 -3.8px 6px', cursor: 'pointer', borderRadius: '50%' }} src={imgImport('cardDetails', 'information.png')} alt="" />
												{showSomeText &&
													<span className='auto__rating'>
														Рейтинг формируется автоматически на основе продаж
													</span>
												}
											</div>
											: ''
									}

									{
										product?.grade == 3 || product?.grade == 2.5 || product?.grade == 2.6 || product?.grade == 2.7 || product?.grade == 2.8 || product?.grade == 2.9 ?
											<div className="rating-inner">
												<img src={imgImport('cardDetails', 'gold-star.png')} alt="" />
												<img src={imgImport('cardDetails', 'gold-star.png')} alt="" />
												<img src={imgImport('cardDetails', 'gold-star.png')} alt="" />
												<img className='information__png' onMouseOver={() => setShowSomeText(true)} onMouseLeave={() => setShowSomeText(false)} style={{ width: '20px', heigth: '20px', margin: '0 0 -3.8px 6px', cursor: 'pointer', borderRadius: '50%' }} src={imgImport('cardDetails', 'information.png')} alt="" />
												{showSomeText &&
													<span className='auto__rating'>
														Рейтинг формируется автоматически на основе продаж
													</span>
												}
											</div>
											: ''
									}

									{
										product?.grade == 2 || product?.grade == 1.5 || product?.grade == 1.6 || product?.grade == 1.7 || product?.grade == 1.8 || product?.grade == 1.9 ?
											<div className="rating-inner">
												<img src={imgImport('cardDetails', 'gold-star.png')} alt="" />
												<img src={imgImport('cardDetails', 'gold-star.png')} alt="" />
												<img className='information__png' onMouseOver={() => setShowSomeText(true)} onMouseLeave={() => setShowSomeText(false)} style={{ width: '20px', heigth: '20px', margin: '0 0 -3.8px 6px', cursor: 'pointer', borderRadius: '50%' }} src={imgImport('cardDetails', 'information.png')} alt="" />
												{showSomeText &&
													<span className='auto__rating'>
														Рейтинг формируется автоматически на основе продаж
													</span>
												}
											</div>
											: ''
									}

									{
										product?.grade == 1 || product?.grade == 1.1 || product?.grade == 1.2 || product?.grade == 1.3 || product?.grade == 1.4 || product?.grade == 1.5 ?
											<div className="rating-inner">
												<img src={imgImport('cardDetails', 'gold-star.png')} alt="" />
												<img className='information__png' onMouseOver={() => setShowSomeText(true)} onMouseLeave={() => setShowSomeText(false)} style={{ width: '20px', heigth: '20px', margin: '0 0 -3.8px 6px', cursor: 'pointer', borderRadius: '50%' }} src={imgImport('cardDetails', 'information.png')} alt="" />
												{showSomeText &&
													<span className='auto__rating'>
														Рейтинг формируется автоматически на основе продаж
													</span>
												}
											</div>
											: ''
									}

								</div>
								<p className="article">Артикул: {product.setNumber}</p>
							</div>
						</div>
						<div className="price">
							<div className={product.salePrice ? 'old-price' : 'price'}>{JSON.stringify(product.price)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} тг.</div>
							{
								product.salePrice && <div className="price">{JSON.stringify(product.salePrice)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} тг.</div>
							}
						</div>
						<div className="count">
							<div className="decrease-count" onClick={() => { count > 1 && setCount(count - 1) }}>-</div>
							<div className="product-count">{count}</div>
							<div className="increase-count" onClick={() => setCount(count + 1)}>+</div>
						</div>
						<div className='_two_cards_with_one' style={{ display: "flex", justifyContent: "space-between" }}>
							{
								isInCart ?
									<button onClick={() => navigate('/basket')} className="remove-cart-btn">
										<img style={{ marginRight: '10px', width: '20px' }} src={imgImport('cardDetails', 'check.png')} alt="" />
										Уже в корзине
									</button>
									:
									<button className="add-cart-btn" onClick={() => setStorage()}>
										<img style={{ marginRight: '20px' }} src={imgImport('header', 'cart.png')} alt="" />

										Добавить в корзину
									</button>
							}
							<div>
								<button className="add-cart-btn" onClick={() => callModal()}>
									Уведомить о поступлении
								</button>
							</div>
						</div>
						{
							showModal &&
							<div className="modal-bg">
								<div className="modal-comment-inner">
									<img onClick={() => setShowModal(false)} className='cross-img' style={{ color: 'white', display: 'block !important' }} src={imgImport('cardDetails', 'cross.png')} alt="" />
									<h1 style={{ fontSize: '30px', color: '#ffffff' }}>Уведомить о поступлении</h1>
									<input placeholder='Ваше имя' type="text" value={userData?.name} className={fakeName ? 'withoutMarginFakeName' : 'comment-title-input'} onInput={(e) => /[0-9]/.test(e.target.value) ? e.target.value = '' : setUserData({ ...userData, name: e.target.value })} />
									{
										fakeName && <span style={{ color: 'red', fontSize: '20px' }}>Введите ваше имя</span>
									}
									<input type="text" onInput={(e) => /[A-Za-z]/.test(e.target.value) ||
										e.target.value.length > 12 ?
										e.target.value = ''
										:
										setUserData({ ...userData, number: e.target.value.replace(e.target.value.substr(0, 2), '+7'), product_id: id })
									} value={userData.number} className={fakeNumber || fakeLengthNumber ? 'withoutMarginFakeNumber' : 'comment-rating-input'} />
									{
										fakeNumber && <span style={{ color: 'red', fontSize: '20px' }}>Введите номер</span>
									}
									{
										fakeLengthNumber && <span style={{ color: 'red', fontSize: '20px' }}>Номер должен быть из 12 чисел</span>
									}
									<textarea placeholder='Комментарий' onInput={(e) => {
										setUserData({ ...userData, comment: e.target.value })
									}} className={fakeComment ? 'withoutMarginFakeComment' : 'comment'} />
									{
										fakeComment && <span style={{ color: 'red', fontSize: '20px' }}>Введите комментарий</span>
									}
									<button onClick={(e) => {
										if (!userData?.name) {
											setFakeName(true);
										}
										if (!userData?.number) {
											setFakeNumber(true)
										}
										if (userData?.number?.length < 12) {
											setFakeLengthNumber(true)
										}
										if (!userData?.comment?.length) {
											setFakeComment(true)
										}
										// 
										if (userData?.name) {
											setFakeName(false);
										}
										if (userData?.number) {
											setFakeNumber(false)
										}
										if (userData?.number?.length >= 12) {
											setFakeLengthNumber(false)
										}
										if (userData?.comment?.length) {
											setFakeComment(false)
										}
										if (userData?.name && userData?.number && userData?.number?.length >= 12 && userData?.comment) {
											setFakeName(true);
											setFakeNumber(true);
											setFakeLengthNumber(true);
											setFakeComment(true);
											setShowModal(false);
											sendNotyfyArrival();
											setUserData({ name: '', number: '+7', comment: '', product_id: null })
										}
									}} className="make-order">Отправить</button>
								</div>
							</div>
						}
						<div style={{ width: '100%', height: '4px', marginTop: '50px' }} className="product-line"></div>
						<div className="characteristics-cont">
							<p className="char-title">Характеристики</p>
							<div className="characteristics">
								{/* {
									product?.characteristic?.map(item => {
										// let value = '';
										// product?.characteristic?.forEach(nextItem => {
										// 	if (item.name === nextItem?.name) value = nextItem?.value;
										// })
										return (
											<div key={item.id} className='_products_characters'>
												<div className='_product_articule'>
													{item.name}
												</div>
												<div className='_product_value'>
													{item.value}
												</div>
											</div>
										)
									})
								} */}

								{product?.characteristic?.length > 4 && !productsMoreFour ?
									product?.characteristic?.map((item, index) => {
										return (
											<div>
												{
													(index <= 3) &&
													<div key={item.id} className='_products_characters'>
														<div className='_product_articule'>
															{item.name}
														</div>
														<div className='_product_value'>
															{item.value}
														</div>
													</div>
												}
												{
													(index === 3) &&
													<p style={{ color: 'blue', marginTop: '-3px', cursor: 'pointer' }} onClick={() => setProductsMoreFour(true)}>Далее...</p>
												}
											</div>
										)
									})
									:
									product?.characteristic?.map((item, index) => {
										// let value = '';
										// product?.characteristic?.forEach(nextItem => {
										// 	if (item.name === nextItem?.name) value = nextItem?.value;
										// })
										return (
											<div key={item.id} className='_products_characters'>
												<div className='_product_articule'>
													{item.name}
												</div>
												<div className='_product_value'>
													{item.value}
												</div>
												{
													(index === product?.characteristic?.length - 1 && index > 3) &&
													<p style={{ color: 'blue', paddingTop: '10px', marginLeft: '-201px', cursor: 'pointer' }} onClick={() => setProductsMoreFour(false)}>Закрыт</p>
												}
											</div>
										)
									})
								}

							</div>
						</div>
					</div>
				</div>
				<div style={{ width: '100%', height: '4px', marginTop: '60px' }} className="product-line"></div>
				<div className="description-container">
					<p className="product-description-title">Описание</p>
					<div className="product-description" dangerouslySetInnerHTML={{ __html: product.description }}></div>
				</div>
				<div style={{ width: '100%', height: '4px', marginTop: '60px' }} className="product-line"></div>
				<Title title="Похожие Товары"></Title>
				<div className="similar-products">
					{similarProducts && similarProducts.map((item, index) => {
						return (
							<div className='mob-product' key={item.id}>
								{
									(index <= 3) &&
									<Card
										setSameProduct={setSameProduct}
										id={item.id}
										key={item.id}
										title={item.title}
										rating={item.rating}
										image={item.image}
										price={item.price}
										salePrice={item.salePrice ? item.salePrice : ''}
										article={item.setNumber}
										created={item.created_at}
									/>
								}
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default ScrollWrapper(CardDetail)
