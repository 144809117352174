import {
  mainPageState,
  aboutPageState,
  catalogState,
  userState,
  authState,
  favsState,
  isInCartState,
} from "./states";
import { actions } from "./constants";
export const MainPageReducer = (state = mainPageState, { type, payload }) => {
  switch (type) {
    case actions.MainPage:
      return { ...state, contents: payload };

    default:
      return state;
  }
};
export const AboutPageReducer = (state = aboutPageState, { type, payload }) => {
  switch (type) {
    case actions.aboutPage:
      return { ...state, contents: payload };

    default:
      return state;
  }
};
export const CatalogPageReducer = (state = catalogState, { type, payload }) => {
  switch (type) {
    case actions.PRODUCT_FETCH:
      const new_payload = {
        ...payload,
        products: {
          data: [...payload.data],
        },
      };
      return { ...state, ...new_payload };
    default:
      return { ...state };
  }
};

export const catalogFilterReducer = (
  state = catalogState,
  { type, payload }
) => {
  switch (type) {
    case actions.filterCat:
      const new_payload = {
        ...payload,
      };
      return { ...state, ...new_payload };
    default:
      return { ...state };
  }
};
export const catalogSubFilterReducer = (
  state = catalogState,
  { type, payload }
) => {
  switch (type) {
    case actions.filterSubCat:
      const new_payload = {
        ...payload,
      };
      return { ...state, ...new_payload };
    default:
      return { ...state };
  }
};
export const userReducer = (state = userState, { type, payload }) => {
  switch (type) {
    case actions.USER_ACTION:
      return { ...state, user: payload };
    case actions.REGISTER_ACTION:
      return { ...state, user: payload };
    case actions.GET_USER:
      return { ...state, ...payload };
    default:
      return state;
  }
};
export const AuthReducer = (state = authState, { type, payload }) => {
  switch (type) {
    case actions.AUTHED:
      return { ...state, isAuthenticated: payload };
    case actions.LOGOUT:
      return { ...state, isAuthenticated: payload };
    default:
      return { ...state };
  }
};
export const favsReducer = (state = favsState, { type, payload }) => {
  switch (type) {
    case actions.getFavs:
      return { ...state, favs: payload };
    default:
      return state;
  }
};

//BASKET
const IS_IN_CART = "IS_IN_CART";

const initialState = {
  item: [],
};

export const isInCartReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_IN_CART: {
      return { ...state, item: action.item };
    }
    default:
      return state;
  }
};

export const isInCartAction = (item) => ({ type: IS_IN_CART, item });

// FAVS
const IS_IN_FAVS = "IS_IN_FAVS";

const initialStateFavs = {
  favster: [],
};

export const isInFavsReducer = (state = initialStateFavs, action) => {
  switch (action.type) {
    case IS_IN_FAVS: {
      return { ...state, favster: action.favster };
    }
    default:
      return state;
  }
};

export const isInFavsAction = (favster) => ({ type: IS_IN_FAVS, favster });

// COMPARISON
const IS_IN_COMPO = "IS_IN_COMPO";

const initialStateCompo = {
  compos: [],
};

export const isInCompoReducer = (state = initialStateCompo, action) => {
  switch (action.type) {
    case IS_IN_COMPO: {
      return { ...state, compos: action.compos };
    }
    default:
      return state;
  }
};

export const isInCompoAction = (compos) => ({ type: IS_IN_COMPO, compos });

// currentPage
const CURRENT_PAGE = "CURRENT_PAGE";

const initialCurrentPageState = {
  currentPage: 0,
};

export const currentPageReducer = (state = initialCurrentPageState, action) => {
  switch (action.type) {
    case CURRENT_PAGE: {
      return { ...state, currentPage: action.currentPage };
    }
    default:
      return state;
  }
};

export const currentPageAction = (currentPage) => ({
  type: CURRENT_PAGE,
  currentPage,
});

const JUST_PAGE = "JUST_PAGE";

const initialJustPageState = {
  justPage: 1,
};

export const justPageReducer = (state = initialJustPageState, action) => {
  switch (action.type) {
    case JUST_PAGE: {
      return { ...state, justPage: action.justPage };
    }
    default:
      return state;
  }
};

export const justPageAction = (justPage) => ({ type: JUST_PAGE, justPage });

// Check with Location if they are equal just equal it to 1

const CHECK_WITH_LOCATION = "CHECK_WITH_LOCATION";

const initialCheckWithLocationState = {
  lookPath: null,
};

export const checkWithLocation = (
  state = initialCheckWithLocationState,
  action
) => {
  switch (action.type) {
    case CHECK_WITH_LOCATION: {
      return { ...state, lookPath: action.lookPath };
    }
    default:
      return state;
  }
};

export const checkWithLocationAction = (lookPath) => ({
  type: CHECK_WITH_LOCATION,
  lookPath,
});

// PageSafer

const PAGE_SAFER = "PAGE_SAFER";

const initialPageSaferState = {
  page: 1,
};

export const pageSaferReducer = (state = initialPageSaferState, action) => {
  switch (action.type) {
    case PAGE_SAFER: {
      return { ...state, page: action.page };
    }
    default: {
      return state;
    }
  }
};

export const pageSaferAction = (page) => ({ type: PAGE_SAFER, page });

const CLEAR_CART = "CLEAR_CART";

const initialClearCartState = {
  clear: true,
};

export const clearCartReducer = (state = initialClearCartState, action) => {
  switch (action.type) {
    case CLEAR_CART: {
      return { ...state, clear: action.clear };
    }
    default: {
      return state;
    }
  }
};

export const clearCartAction = (clear) => ({ type: CLEAR_CART, clear });

const AVATAR_SAFER = "AVATAR_SAFER";

const initialAvatarSaferState = {
  ava: null,
};

export const avatarSaferReducer = (state = initialAvatarSaferState, action) => {
  switch (action.type) {
    case AVATAR_SAFER: {
      return { ...state, ava: action.ava };
    }
    default: {
      return state;
    }
  }
};

export const avatarSaferAction = (ava) => ({ type: AVATAR_SAFER, ava });

const TYPE_IMAGE_SAFER = "TYPE_IMAGE_SAFER";

const initialtypeImageSaferState = {
  imag: null,
};

export const typeImageSaferReducer = (
  state = initialtypeImageSaferState,
  action
) => {
  switch (action.type) {
    case TYPE_IMAGE_SAFER: {
      return { ...state, imag: action.imag };
    }
    default: {
      return state;
    }
  }
};

export const typeImageSaferAction = (imag) => ({
  type: TYPE_IMAGE_SAFER,
  imag,
});

const UPDATED_AVATAR = "UPDATED_AVATAR";

const initialUpdateAvatarState = {
  avat: null,
};

export const updateAvatarReducer = (
  state = initialUpdateAvatarState,
  action
) => {
  switch (action.type) {
    case UPDATED_AVATAR: {
      return { ...state, avat: action.avat };
    }
    default: {
      return state;
    }
  }
};

export const updateAvatarAction = (avat) => ({ type: UPDATED_AVATAR, avat });
