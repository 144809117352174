import React, { Fragment, Component } from "react";
import { imgImport } from "../helpers/helper";
import PropTypes from "prop-types";
import '../style/components/pagination.css'
import { compose } from "redux";
import { connect } from "react-redux";
import { justPageAction, pageSaferAction } from "../store/reducers";
import { isInPageSaferSelector } from "../store/selectors";
import _ from "lodash";

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

const range = (from, to, step = 1) => {
	let i = from;
	const range = [];

	while (i <= to) {
		range.push(i);
		i += step;
	}

	return range;
};

class Pagination extends Component {
	constructor(props) {
		super(props);
		const { totalRecords = null, pageLimit = 9, pageNeighbours = 0, currentPage = 1, setUpdate, justPage, locator, editId, firstPage } = props;
		this.pageLimit = typeof pageLimit === "number" ? pageLimit : 9;
		this.totalRecords = typeof totalRecords === "number" ? totalRecords : 9;
		this.current_page = currentPage;
		this.setUpdate = setUpdate;
		this.justPage = justPage;
		this.locator = locator;
		this.editId = editId;
		this.firstPage = firstPage;

		this.pageNeighbours =
			typeof pageNeighbours === "number"
				? Math.max(0, Math.min(pageNeighbours, 2))
				: 0;
		// ('e',this.totalRecords,this.pageLimit)
		this.totalPages = Math.ceil(this.totalRecords / this.pageLimit);
		this.state = { totalPage: this.totalPages }

		this.state = { firstPage: firstPage };
		this.state = { location: locator };
		this.state = { loEditId: editId };

		this.setState({ totalPage: this.totalPages });
		this.setState({ totalLocator: this.totalRecords });

	}

	componentDidMount() {
		this.gotoPage(this.current_page, true);

		window.addEventListener('popstate', this.moveTo);
		window.addEventListener('pushstate', this.moveTo);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.totalRecords != this.props.totalRecords) {
			this.totalPages = Math.ceil(nextProps.totalRecords / nextProps.pageLimit);

			this.setState({ currentPage: nextProps.currentPage });

			this.gotoPage(nextProps.currentPage, true)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		// if (this.totalPages === prevState.totalPage) {
		// this.totalPages = Math.ceil(prevProps.totalRecords / prevProps.pageLimit);
		// this.state.currentPage = this.current_page;
		// } else if (this.totalPages !== prevState.totalPage) {
		// this.totalPages = Math.ceil(prevProps.totalRecords / prevProps.pageLimit);
		// }
		// COLOR
	}

	componentWillUnmount() {
		window.removeEventListener('popstate', this.moveTo);
		window.removeEventListener('pushstate', this.moveTo);
	}

	moveTo = () => {
		const urlParams = _.split(window.location.pathname, '/');
		const page = urlParams.includes('search') ? urlParams[4] : urlParams[3]

		this.gotoPage(page, true);
	};

	gotoPage = (page, disableNavigation) => {
		const { onPageChanged = f => f } = this.props;

		const urlParams = _.split(window.location.pathname, '/');
		const currentPage = Math.max(0, Math.min(page, this.totalPages));
		this.state.currentPage = currentPage;

		const paginationData = {
			currentPage,
			totalPages: this.totalPages,
			pageLimit: this.pageLimit,
			totalRecords: this.totalRecords
		};

		this.setState({ currentPage }, () => onPageChanged(paginationData));

		if (!disableNavigation) {
			if (this.props.locator.includes('search')) {
				this.props.navigate(`/${urlParams[1]}/` + `${urlParams[2]}/` + `${urlParams[3]}/` + currentPage);
			} else {
				this.props.navigate(`/${urlParams[1]}/` + `${urlParams[2]}/` + currentPage);
			}
		}
	};

	handleClick = (page, evt) => {
		evt.preventDefault();
		this.gotoPage(page);
	};

	handleMoveLeft = evt => {
		evt.preventDefault();
		this.gotoPage(this.state.currentPage - 1);
	};

	handleMoveRight = evt => {
		evt.preventDefault();
		this.gotoPage(this.state.currentPage + 1);
	};

	handleMoveStart = evt => {
		evt.preventDefault();
		this.gotoPage(1);
	}

	handleMoveEnd = evt => {
		evt.preventDefault();
		this.gotoPage(this.totalPages)
	}

	fetchPageNumbers = () => {
		const totalPages = this.totalPages;
		const currentPage = this.state.currentPage;
		const pageNeighbours = this.pageNeighbours;

		const totalNumbers = this.pageNeighbours * 2 + 3;
		const totalBlocks = totalNumbers + 2;
		// (totalPages)
		if (totalPages > totalBlocks) {
			let pages = [];

			const leftBound = currentPage - pageNeighbours;
			const rightBound = currentPage + pageNeighbours;
			const beforeLastPage = totalPages - 1;

			const startPage = leftBound > 2 ? leftBound : 2;
			const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

			pages = range(startPage, endPage);

			const pagesCount = pages.length;
			const singleSpillOffset = totalNumbers - pagesCount - 1;

			const leftSpill = startPage > 2;
			const rightSpill = endPage < beforeLastPage;

			const leftSpillPage = LEFT_PAGE;
			const rightSpillPage = RIGHT_PAGE;

			if (leftSpill && !rightSpill) {
				const extraPages = range(startPage - singleSpillOffset, startPage - 1);
				pages = [leftSpillPage, ...extraPages, ...pages];
			} else if (!leftSpill && rightSpill) {
				const extraPages = range(endPage + 1, endPage + singleSpillOffset);
				pages = [...pages, ...extraPages, rightSpillPage];
			} else if (leftSpill && rightSpill) {
				pages = [leftSpillPage, ...pages, rightSpillPage];
			}
			return [1, ...pages, totalPages];
		}

		return range(1, totalPages);
	};

	render() {
		if (!this.totalRecords) return null;

		if (this.totalPages === 1) return null;

		let { currentPage } = this.state;
		this.justPage.justPage = currentPage;
		const pages = this.fetchPageNumbers();

		return (
			<>
				<img style={{ cursor: 'pointer' }} onClick={this.handleMoveStart} className='pagination-left' src={imgImport('pagination', 'arrow-left.png')} />
				<Fragment>
					<nav aria-label="Countries Pagination">
						<ul className="pagination">
							{pages.map((page, index) => {
								if (page === LEFT_PAGE)
									return (
										<li key={index} className="page-item">
											<a
												className="page-link"
												href="#"
												aria-label="Previous"
												onClick={this.handleMoveLeft}
											>
												<span aria-hidden="true">&laquo;</span>
											</a>
										</li>
									);

								if (page === RIGHT_PAGE)
									return (
										<li key={index} className="page-item">
											<a
												className="page-link"
												href="#"
												aria-label="Next"
												onClick={this.handleMoveRight}
											>
												<span aria-hidden="true">&raquo;</span>
											</a>
										</li>
									);

								return (
									<li
										key={index}
										className={`page-item${currentPage === page ? " active-pagination " : ""
											}`}
									>
										<a
											className="page-link"
											href="#"
											onClick={e => {
												// if (page > 1) {
												// 	this.setState({ newCurrentPage: page });
												// }
												this.justPage.justPage = page;
												this.setUpdate(true)
												this.handleClick(page, e)
											}}
										>
											{page}
										</a>
									</li>
								);
							})}
						</ul>
					</nav>
				</Fragment>
				<img style={{ cursor: 'pointer' }} onClick={this.handleMoveEnd} className='pagination-right' src={imgImport('pagination', 'arrow-right.png')} />
			</>
		);
	}
}

Pagination.propTypes = {
	totalRecords: PropTypes.number.isRequired,
	pageLimit: PropTypes.number,
	pageNeighbours: PropTypes.number,
	onPageChanged: PropTypes.func,
	currentPage: PropTypes.number,
	navigate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	majorPage: isInPageSaferSelector(state)
})

const mapDispatchToProps = (dispatch) => ({
	pageCreater: (page) => dispatch(justPageAction(pageSaferAction(page)))
})

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
)(Pagination)

// export default Pagination;