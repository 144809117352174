import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { _storage } from '../../../helpers/helper';
import { fetchObjectProduct } from "../../../store/actions";
import Card from '../../../components/Card';
import '../../../style/components/favs.css'
import api from '../../../helpers/api';
export const Favorite = () => {
	const dispatch = useDispatch();
	const [favs, setFavs] = useState([]);
	const [deletedFavs, setDeletedFavs] = useState(false);
	const [isFavChanged, setIsFavChanged] = useState(false);
	const [favsMany, setFavsMany] = useState(true);

	useEffect(() => {
		let storage = _storage.get('smartgfav') ? JSON.parse(_storage.get('smartgfav')) : [];
		if (storage.length == 0) {
			setFavsMany(false)
		}
		if (typeof storage == 'object' && storage.length > 0) {
			const promises = storage.map((item) => {
				return api._fetchObjectProduct(item)
			})
			const products = [];
			Promise.all(promises).then((responses) => {
				responses.forEach((res) => {
					products.push(res.data.product)
					console.log(res.data.product);
				})
				setFavs(products)
			})
		}
		else {
			dispatch(fetchObjectProduct(storage)).then(res => {
				setFavs(prevState => {
					return [...prevState, res?.product]
				})
			})
		}
	}, [favs.length, deletedFavs])

	return (
		<div className="container">
			<div className="favorites">
				<p className='product_Title'>У вас {favsMany ? favs.length : 0} товара в избранном </p>
				<div className="favorites-inner">
					{favsMany &&
						favs && favs?.length > 0 ? favs?.map(item => {
							return (
								<div style={{ margin: '0 5px' }}>
									<Card
										isFavChanged={isFavChanged}
										setIsFavChanged={setIsFavChanged}
										id={item?.id}
										key={item?.id}
										title={item?.title}
										rating={item?.rating}
										image={item?.image}
										price={item?.price}
										salePrice={item?.salePrice ? item?.salePrice : ''}
										article={item?.setNumber}
										created={item?.created_at}
										description={item?.description}
										deletedFavs={deletedFavs}
										setDeletedFavs={setDeletedFavs}
									/>
								</div>
							)
						})
						:
						<p className='none_Product'>Продукты отсутствуют</p>
					}
				</div>
			</div>
		</div>
	)
}