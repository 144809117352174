import { applyMiddleware, combineReducers, createStore } from "redux";
import {
	MainPageReducer,
	AboutPageReducer,
	CatalogPageReducer,
	catalogFilterReducer,
	catalogSubFilterReducer,
	userReducer,
	AuthReducer,
	favsReducer,
	isInCartReducer,
	isInFavsReducer,
	isInCompoReducer,
	currentPageReducer,
	justPageReducer,
	checkWithLocation,
	pageSaferReducer,
	clearCartReducer,
	avatarSaferReducer,
	typeImageSaferReducer,
	updateAvatarReducer

} from "./reducers";
import thunk from 'redux-thunk';
const middleware = [thunk];

const reducers = combineReducers({
	userInfo: userReducer,
	mainPage: MainPageReducer,
	catalog: CatalogPageReducer,
	catalog: catalogFilterReducer,
	catalog: catalogSubFilterReducer,
	aboutPage: AboutPageReducer,
	isAuthed: AuthReducer,
	favs: favsReducer,
	isInCart: isInCartReducer,
	isInFavs: isInFavsReducer,
	isInCompo: isInCompoReducer,
	currPage: currentPageReducer,
	justPagenation: justPageReducer,
	lookWithPath: checkWithLocation,
	pageSafer: pageSaferReducer,
	clearCart: clearCartReducer,
	avatarSafer: avatarSaferReducer,
	typeImage: typeImageSaferReducer,
	updateAvat: updateAvatarReducer,
});

export default createStore(reducers, (applyMiddleware(...middleware)));