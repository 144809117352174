
export const mainPageState = {
	contents: null,
}
export const catalogState = {
	products: null
}

export const aboutPageState = {
	contents: null,
}
export const userState = {
	user: undefined
}
export const authState = {
	isAuthenticated: false
};
export const favsState = {
	favs: []
}
export const isInCartState = {
	isInCart: []
}