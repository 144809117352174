const options = {
    map: {
        defaultState: {
            center: [ 43.261096, 76.947241 ],
            zoom: 11
        }
    },
    polygon: {
        geometry: [[
            [43.236199183618325, 76.80569890640082],
            [43.232461003284634, 76.7973764071359],
            [43.19193387625025, 76.8293012413891],
            [43.192522942790546, 76.83088072418464],
            [43.1849865095807, 76.83894880890143],
            [43.176483640691615, 76.84957080274853],
            [43.169447804107016, 76.85094409376414],
            [43.17843091549585, 76.85695224195753],
            [43.17640431936389, 76.87294127716555],
            [43.17816315322965, 76.90066458954347],
            [43.183764377772945, 76.92371149988624],
            [43.18640228918786, 76.93032046289893],
            [43.22521118077713, 76.98217597245043],
            [43.236950971602745, 76.97006218255055],
            [43.24473033127303, 76.97666046035462],
            [43.25263575695536, 76.98138114822086],
            [43.26091652314585, 76.98206779372869],
            [43.26875803628102, 76.98595431081807],
            [43.273493259403175, 76.98402312032735],
            [43.27898063658809, 76.98380854360614],
            [43.28868886329617, 76.98836488022049],
            [43.293140396142086, 76.9934288908406],
            [43.30595389874658, 77.01056704052502],
            [43.325072763161906, 76.99860882139731],
            [43.33558040066188, 77.00147396187825],
            [43.34287299415695, 76.9948797583523],
            [43.344823300186185, 76.97139006891808],
            [43.34983442387478, 76.9633219842013],
            [43.355033024338624, 76.9464133385714],
            [43.35046078504131, 76.92349654474819],
            [43.347516830954945, 76.92117911615931],
            [43.350006586974594, 76.90537761796475],
            [43.34052757215478, 76.8925573010253],
            [43.31095135499365, 76.86955467651357],
            [43.31266955547451, 76.83470234552591],
            [43.30901595842284, 76.8366645654411],
            [43.3012002538073, 76.83698411635547],
            [43.29538728565714, 76.83350334513734],
            [43.28493998130562, 76.82774343233989],
            [43.265998850913086, 76.78764198748215],
            [43.262700260860214, 76.78650177944152],
            [43.25895931416693, 76.79163310032541],
            [43.255811401088295, 76.79597342458821],
            [43.248869291922986, 76.79894633233022],
            [43.2506168892915, 76.7965245809593],
            [43.24535642677559, 76.79029457398926],
            [43.236199183618325, 76.80569890640082],
        ]],
        options: {
            fillColor: "#ed4543",
            fillOpacity: 0.6,
            strokeColor: "#595959",
            strokeWidth: 5,
            strokeOpacity: 0.9
        }
    },
    placemark: {
        geometry: [ 43.315382, 76.957125 ],
        properties: {
            balloonContent: "Казахстан, Алматы, проспект Суюнбая, 215",
            iconCaption: "P.I.T."
        },
        options: {
            preset: "islands#redShoppingIcon"
        },
    }
}

export default options