import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import '../../style/pages/whosales.css';
import BreadCumps from '../../components/BreadCumps';
import Title from '../../components/Title';
import '../../style/pages/returns.css';
import Hamburger from '../../components/Hamburger';
import { getConditionsAction, setConditionsDataAction } from '../../store/actions';

const Returns = () => {

	const dispatch = useDispatch();
	const [userData, setUserData] = useState({});
	const [conditions, setConditions] = useState();

	useEffect(() => {
		dispatch(getConditionsAction()).then(res => {
			setConditions(res)
		})
	}, [])

	const handleSendData = () => {
		dispatch(setConditionsDataAction(userData));
	}

	return (
		<div className='returns-page'>
			<div className="container">
				<Hamburger />
				<Title style={{ margin: '-70px 0 0 0' }} title="Условия возврата"></Title>
				<BreadCumps
					items={
						[
							{
								link: "/",
								name: 'Главная'
							},
							{
								link: "/returns",
								name: 'Условия возврата'
							},
						]
					}
				/>
				<div className="returns-page-container">
					<div style={{ marginBottom: '30px' }} className='conditions-content' dangerouslySetInnerHTML={{ __html: conditions?.content }}></div>
					<div className='returns-name-email'>
						<div className='returns-name'>
							<input className='returns-name-input' onChange={(e) => setUserData({ ...userData, name: e.target.value })} placeholder='Имя*' type="text" />
						</div>
						<div className='returns-email'>
							<input className='returns-email-input' onChange={(e) => setUserData({ ...userData, surname: e.target.value })} placeholder='Ваша почта*' type="text" />
						</div>
					</div>
					{/* <div className='returns-comment'> */}
					<textarea className='returns-comment' onInput={(e) => setUserData({ ...userData, text: e.target.value })} placeholder='Ваш вопрос, отзыв или пожелание*' />
					{/* </div> */}
					<button style={{ margin: '0 0 10px 0', borderRadius: 'inherit' }} onClick={() => handleSendData()} className='make-order'>
						Отправит
					</button>
				</div>
			</div>
		</div>
	)
}

export default Returns;