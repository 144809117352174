import React from "react";
import Hamburger from "../../components/Hamburger";
import Title from "../../components/Title";
import BreadCumps from "../../components/BreadCumps";

const PrivacyPolic = () => {
	return (
		<div className="container">
			<Hamburger />
			<Title style={{ margin: '-70px 0 0 0' }} title="Политика конфиденциальности"></Title>
			<BreadCumps
				items={
					[
						{
							link: "/",
							name: 'Главная'
						},
						{
							link: "/privacy-policy",
							name: 'Политика конфиденциальности'
						},
					]
				}
			/>
			<div style={{ marginBottom: '30px', padding: '5px' }} className="PrivacyPolic-inner">
				Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности) действует в отношении всей информации, которую Интернет-магазин «INGCO»,  расположенный на доменном имени www.ingcotools.kz, может получить о Пользователе во время использования сайта Интернет-магазина, программ и продуктов Интернет-магазина.

				1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ

				1.1.   В настоящей Политике конфиденциальности используются следующие термины:

				1.1.1. «Администрация сайта Интернет-магазина (далее – Администрация сайта) » – уполномоченные сотрудники на управления сайтом, действующие от имени ТОО "УПТК-Рус",  которые организуют и (или) осуществляет обработку персональных данных, а также определяет цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.

				1.1.2. «Персональные данные» - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).

				1.1.3. «Обработка персональных данных» - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.

				1.1.4. «Конфиденциальность персональных данных» - обязательное для соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.

				1.1.5. «Пользователь сайта Интернет-магазина (далее ‑ Пользователь)» – лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее Сайт интернет-магазина.

				1.1.6. «Cookies» — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.

				1.1.7. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.
			</div>
		</div>
	);
};

export default PrivacyPolic;