export const mainPageSelector = state => state.mainPage;
export const aboutPageSelector = state => state.aboutPage;
export const catalogSelector = state => state.catalog;
export const authSelector = state => state.isAuthed;
export const favsSelector = state => state.favs;
export const isInCartSelector = state => state.isInCart;
export const isInFavsSelector = state => state.isInFavs;
export const isInCompoSelector = state => state.isInCompo;
export const isInCurrentPageSelector = state => state.currPage;
export const isInJustPageSelector = state => state.justPagenation;
export const isInCheckWithLocation = state => state.lookWithPath;
export const isInPageSaferSelector = state => state.pageSafer;
export const isInClearCartSelector = state => state.clearCart;
export const isInAvatarSaferSelector = state => state.avatarSafer;
export const isInTypeImageSaferSelector = state => state.typeImage;
export const isInUpdatedAvatarSelector = state => state.updateAvat;