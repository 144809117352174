import React, { useEffect } from 'react'
import '../style/components/footer.css'
import { imgImport } from '../helpers/helper.js';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react'
import { useDispatch } from 'react-redux';
import { callbackAction, getSocialFormAction, setConditionsDataAction } from '../store/actions';
const Footer = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [showModal, setShowModal] = useState(false)
	const [showSuccess, setShowSuccess] = useState(false)
	const callModal = () => setShowModal(true)
	const [err, setErr] = useState(false)
	const [socialForms, setSocialForms] = useState({});
	const [userData, setUserData] = useState({
		name: '',
		surname: '+7',
		text: ''
	});
	const [fakeName, setFakeName] = useState(false);
	const [fakeNumber, setFakeNumber] = useState(false);
	const [fakeComment, setFakeComment] = useState(false);
	const [fakeLengthNumber, setFakeLengthNumber] = useState(false);
	const [callback, setCallBack] = useState({
		name: '',
		number: '+7',
		comment: '',
		product_id: ''
	})

	useEffect(() => {
		getSocialFormAction().then(res => {
			setSocialForms(res)
		});
	}, [])

	const sendConditionsData = () => {
		dispatch(setConditionsDataAction(userData));
	}

	const sendCallback = () => {
		if (callback.name.length == 0 || callback.phone.length == 0 || callback.comment.length == 0) {
			setErr(true)
			return
		} else {
			dispatch(callbackAction(callback)).then(res => {
				setShowModal(false)
				setShowSuccess(true)
				setTimeout(() => {
					setShowSuccess(false)
				}, 1500);
			})
		}
	}
	const setCall = (type, val) => {
		var reg = new RegExp('^[0-9]$');
		if (val.length < 2 || reg.test(val) || val.length > 12) {
			return
		} else {
			setCallBack({ ...callback, [type]: val })
		}
	}

	return (
		<footer>
			{
				showSuccess &&
				<div className="success-modal">
					Ваш заявка принята, спасибо!
				</div>
			}
			{/* copied */}
			{
				showModal &&
				<div className="modal-bg">
					<div className="modal-comment-inner">
						<img onClick={() => setShowModal(false)} className='cross-img' src={imgImport('cardDetails', 'cross.png')} alt="" />
						<h1 style={{ fontSize: '30px', color: '#ffffff' }}>Уведомить о поступлении</h1>
						<input placeholder='Ваше имя' type="text" value={userData?.name} className={fakeName ? 'withoutMarginFakeName' : 'comment-title-input'} onInput={(e) => /[0-9]/.test(e.target.value) ? e.target.value = '' : setUserData({ ...userData, name: e.target.value })} />
						{
							fakeName && <span style={{ color: 'red', fontSize: '20px' }}>Введите ваше имя</span>
						}
						<input onInput={(e) => /[A-Za-z]/.test(e.target.value) ||
							e.target.value.length > 12 ?
							e.target.value = ''
							:
							setUserData({ ...userData, surname: e.target.value.replace(e.target.value.substr(0, 2), '+7') })
						} value={userData.surname} placeholder={userData.surname} className={fakeNumber || fakeLengthNumber ? 'withoutMarginFakeNumber' : 'comment-rating-input'} type="text" />
						{
							fakeNumber && <span style={{ color: 'red', fontSize: '20px' }}>Введите номер</span>
						}
						{
							fakeLengthNumber && <span style={{ color: 'red', fontSize: '20px' }}>Номер должен быть из 12 чисел</span>
						}
						<textarea placeholder='Комментарий' onInput={(e) => {
							setUserData({ ...userData, text: e.target.value })
						}} className={fakeComment ? 'withoutMarginFakeComment' : 'comment'} />
						{
							fakeComment && <span style={{ color: 'red', fontSize: '20px' }}>Введите комментарий</span>
						}
						<button onClick={(e) => {
							if (!userData?.name) {
								setFakeName(true);
							}
							if (!userData?.surname) {
								setFakeNumber(true)
							}
							if (userData?.surname?.length < 12) {
								setFakeLengthNumber(true)
							}
							if (!userData?.text?.length) {
								setFakeComment(true)
							}
							// 
							if (userData?.name) {
								setFakeName(false);
							}
							if (userData?.surname) {
								setFakeNumber(false)
							}
							if (userData?.surname?.length >= 12) {
								setFakeLengthNumber(false)
							}
							if (userData?.text?.length) {
								setFakeComment(false)
							}
							if (userData?.name && userData?.surname && userData?.surname?.length >= 12 && userData?.text) {
								setFakeName(false);
								setFakeNumber(false);
								setFakeLengthNumber(false);
								setFakeComment(false);
								setShowModal(false);
								sendConditionsData();
								setUserData({ name: '', surname: '+7', text: '' })
							}
						}} className="make-order">Отправить</button>
					</div>
				</div>
			}
			<div className="container">
				<div className="footer-wrap">
					<div className="logo-footer">
						<img src={imgImport('footer', 'Logo.png')} alt="" />
						<p>Полный цикл изготовления, от производства запчастей до финальной сборки и упаковки.</p>
					</div>
					<div className="info">
						<p className="info-title">
							Информация
						</p>
						<a style={{ cursor: 'pointer' }} onClick={() => navigate('/about')} className="info-item">
							О компании
						</a>
						<a style={{ cursor: 'pointer' }} onClick={() => navigate('/delivery')} className="info-item">
							Информация о доставке
						</a>
						<a style={{ cursor: 'pointer' }} onClick={() => navigate('/privacy-policy')} className="info-item">
							Политика конфиденциальности
						</a>
						{/* <a className="info-item">
                            Пользовательское соглашение
                        </a> */}
					</div>
					<div className="support">
						<p className="support-title">
							Служба поддержки
						</p>
						<a onClick={() => callModal()} className="support-item">
							Связаться с нами
						</a>
						<a onClick={() => navigate('/returns')} className="support-item">
							Условия возврата
						</a>
						{/* <a className="support-item">
                            Карта сайта
                        </a> */}
					</div>
					<div className="additional">
						<p className="additional-title">
							Дополнительно
						</p>
						<a onClick={() => navigate('/brand')} className="additional-item">
							Бренд
						</a>
						{/* <a onClick={() => navigate('/certificates')} className="additional-item">
                            Подарочные сертификаты
                        </a> */}
						<a onClick={() => navigate('/partners')} className="additional-item">
							Партнёры
						</a>
						<a onClick={() => navigate('/offer-agreement')} className="additional-item">
							Договор оферты
						</a>
						{/* <a className="additional-item">
                            Товары со скидкой
                        </a> */}
					</div>
					<div className="icons">
						<p className="icons-title">Соцсети</p>
						<div className="icons-wrap">
							{
								socialForms?.socials?.map(imag => {
									return (
										<a href={imag.link}>
											<img src={"https://api.pittools.kz/storage/" + imag.icon} alt="" />
										</a>
									)
								})
							}
						</div>
						{
							socialForms.footer && socialForms?.footer?.map(item => {
								return (
									<>
										<div style={{ marginTop: "3px" }}>
											<a style={{ textDecoration: "none", color: "white", cursor: "pointer", fontSize: "15px" }} href={`tel:${item.phone}`}>{item.phone}</a>
										</div>
										<div style={{ marginTop: "3px" }}>
											<a style={{ textDecoration: "none", color: "white", cursor: "pointer", fontSize: "15px" }} href={`tel:${item.phone2}`}>{item.phone2}</a>
										</div>
										<div style={{ marginTop: "3px" }}>
											<a style={{ textDecoration: "none", color: "white", cursor: "pointer", fontSize: "15px" }} href={`tel:${item.phone3}`}>{item.phone3}</a>
										</div>
									</>
								)
							})
						}
					</div>
				</div>
				<hr className="footer-white-line" />
				<div className="bottom-content">
					<p className="copyright">© 2022. Все Права Защищены.</p>
					<a href='https://www.a-lux.kz/' style={{ textDecoration: 'none', color: 'white' }} className="a-lux">Разработано в A-Lux</a>
				</div>
			</div>
		</footer>
	)
}

export default Footer
