import React from 'react'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { registerAction } from "../../store/actions";
import BreadCumps from '../../components/BreadCumps'
import Hamburger from '../../components/Hamburger';
import Title from '../../components/Title'
import { Link, useNavigate } from "react-router-dom";
import { ScrollWrapper } from '../../components/ScrollWrapper';
import '../../style/pages/register.css'
import { imgImport, _storage } from "../../helpers/helper";
import imags from '../../assets/images/ey.png';

const Register = () => {
	const [showPass, setShowPass] = useState(true);
	const [showWrongName, setShowWrongName] = useState(false);
	const [showPassRet, setShowPassRet] = useState(true);
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [user, setUser] = useState({
		name: '',
		email: '',
		password: '',
		password_confirm: ''
	})
	const [error, setError] = useState('')
	const registration = () => {

		if (user.password.length >= 8 && user.password === user.password_confirm) {
			dispatch(registerAction(user)).then(res => {
				// (res)
				debugger
				navigate('/login')
			}).catch(err => {
				setError('Данная почта уже используется!')
			})
		}
	}
	return (
		<div style={{ padding: '0px 0px 4rem' }}>
			<div className="container">
				<Hamburger />
				<BreadCumps
					items={
						[
							{
								link: "/",
								name: 'Главная'
							},
							{
								link: "/register",
								name: 'Регистрация'
							},
							// {
							//     link: "/",
							//     name: checkers['category']? checkers['category'].name : 'Catalog'
							// }
						]
					}
				/>
				<Title title="Регистрация" style={{ textAlign: 'center' }}></Title>
				<div className="register-form">
					<div className="register-inner">
						<div className="form">
							<img className='login-register-input' src={imgImport('cabinet', 'user.png')} alt="" />
							<p>
								<input onChange={(e) => {
									if (/^[а-яА-ЯёЁ\s]+$/.test(e.target.value) || e.target.value === "") {
										setUser({ ...user, name: e.target.value })
										setShowWrongName(false);
									} else {
										setShowWrongName(true);
									}
								}} value={user.name} type="text" placeholder="Ваше имя" />
								{showWrongName && <span style={{ color: 'red', margin: '2px 0 0 5px' }}>Используйте русские буквы</span>}
							</p>
							<img className='login-register-email' src={imgImport('cabinet', 'email.png')} alt="" />
							<p className='_for_relative'>
								<input onInput={(e) => setUser({ ...user, email: e.target.value })} type="text" placeholder="Ваша почта" />
								{error && <span style={{ color: 'red', margin: '2px 0 0 5px' }}>{error}</span>}
							</p>
							<img className='login-register-password' src={imgImport('cabinet', 'password.png')} alt="" />
							<p>
								<input onInput={(e) => setUser({ ...user, password: e.target.value })} type={showPass ? 'password' : 'text'} placeholder="Введите пароль" />
								{showPass ?
									<img className='_eye_orig' src={imags} onClick={() => setShowPass(false)} alt="" />
									: <img className='_eye_orig' src={imags} onClick={() => setShowPass(true)} alt="" />
								}
							</p>
							<img className='login-register-confirm-password' src={imgImport('cabinet', 'password.png')} alt="" />
							<p>
								<input onInput={(e) => setUser({ ...user, password_confirm: e.target.value })} type={showPassRet ? 'password' : 'text'} placeholder="Потверждение пароля" />
								{showPassRet ?
									<img className='_eye_orig' src={imags} onClick={() => setShowPassRet(false)} alt="" />
									: <img className='_eye_orig' src={imags} onClick={() => setShowPassRet(true)} alt="" />
								}
								{(user.password.length > 0 && user.password.length <= 8) && <span style={{ color: 'red', margin: '2px 0 0 5px' }}>Пароль должен состоять из 8 букв!</span>}
								{user.password.length > 0 && user.password !== user.password_confirm && <span style={{ color: 'red', margin: '2px 0 0 5px' }}>Пароли должны совпадать!</span>}
							</p>
							<button onClick={() => registration()} style={{ margin: '0 auto' }} className="make-order">
								Регистрация

							</button>
							<div className='register-titles'>
								<Link className='login-registered' to="/reset">
									Забыли пароль?
								</Link>
								<Link className='login-reset' to="/login">
									Войти
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ScrollWrapper(Register)
