export const actions = {
	MainPage: 'fetchMain',
	PRODUCT_FETCH: 'Products/GET',
	filterCat: 'filterByCat',
	filterSubCat: 'filterBySubCat',
	aboutPage: 'fetchAbout',
	REGISTER_ACTION: 'postUser',
	GET_USER: 'login',
	AUTHED: 'auth',
	getFavs: 'getFavs',
	getIsInCart: 'getIsInCart'
}